import { useState, useEffect } from "react";
import { useRouter } from "next/navigation";
import { MessageType } from "../Dropdowns/MessageTypeDropDown";
import { Card } from "../ui/card";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { Textarea } from "../ui/textarea";
import { Toggle } from "../ui/toggle";
import { Button } from "../ui/button";
import { Sparkles, Undo2 } from "lucide-react";
import { t } from "i18next";
import { cn } from "../../lib/utils";
import { useProFeatureDialog } from "./ProFeatureContext";

interface RichTextEditorV2Props {
  messageType: MessageType;
  emailInputRef: React.RefObject<HTMLTextAreaElement>;
  setEmail: (value: string) => void;
  unlimitedUser: boolean;
  isV2?: boolean;
  onV2Change?: (enabled: boolean) => void;
}

export function RichTextEditorV2({
  messageType,
  emailInputRef,
  setEmail,
  unlimitedUser,
  isV2,
  onV2Change
}: RichTextEditorV2Props) {
  const router = useRouter();
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [headline, setHeadline] = useState("");
  const [hashtags, setHashtags] = useState("");
  const [connectionName, setConnectionName] = useState("");
  const { openDialog } = useProFeatureDialog();

  const isV2Visible = isV2 ?? false;

  const handleV2Toggle = (enabled: boolean) => {
    if (!unlimitedUser && enabled) {
      // Show version 2 editor but keep inputs disabled
      onV2Change?.(true);
      openDialog("richTextV2" as const);
      return;
    }
    onV2Change?.(enabled);
  };

  const handleInputInteraction = (e: React.MouseEvent | React.FocusEvent) => {
    if (isV2Visible && !unlimitedUser) {
      e.preventDefault();
      openDialog("richTextV2" as const);
    }
  };

  const isDisabled = !unlimitedUser;

  useEffect(() => {
    if (emailInputRef.current && isV2Visible) {
      let formattedContent = '';
      
      switch (messageType) {
        case "e-mail":
          formattedContent = `Subject: ${subject}\n\n${content}`;
          break;
          
        case "linkedin-post":
          formattedContent = `Headline: ${headline}\n\nContent:\n${content}\n\nHashtags: ${hashtags}`;
          break;
          
        case "linkedin-message":
          formattedContent = `To: ${connectionName}\n\n${content}`;
          break;
          
        case "whatsapp-message":
          formattedContent = content;
          break;
          
        case "slack-message":
          formattedContent = content;
          break;
          
        default:
          formattedContent = content;
      }
      
      emailInputRef.current.value = formattedContent;
      setEmail(formattedContent);
    }
  }, [
    subject,
    content,
    headline,
    hashtags,
    connectionName,
    messageType,
    isV2Visible
  ]);

  const renderEditor = () => {
    switch (messageType) {
      case "linkedin-post":
        return (
          <Card className="p-4 border-[#0A66C2] dark:border-[#0A66C2]/50">
            <div className="flex items-center gap-2 mb-3">
              <div className="w-8 h-8 rounded bg-[#0A66C2] flex items-center justify-center text-white font-semibold">
                in
              </div>
              <div className="text-sm text-gray-600 dark:text-gray-400">
                LinkedIn Post
              </div>
            </div>
            <div className="space-y-4">
              <div>
                <Label>Headline</Label>
                <Input
                  value={headline}
                  onChange={(e) => unlimitedUser && setHeadline(e.target.value)}
                  placeholder="Enter attention-grabbing headline"
                  onMouseDown={handleInputInteraction}
                  onFocus={handleInputInteraction}
                  className={cn(
                    "mt-1.5",
                    isDisabled && "cursor-not-allowed opacity-60"
                  )}
                  disabled={isDisabled}
                />
              </div>
              <div>
                <Label>Content</Label>
                <Textarea
                  ref={emailInputRef}
                  value={content}
                  onChange={(e) => unlimitedUser && setContent(e.target.value)}
                  onMouseDown={handleInputInteraction}
                  onFocus={handleInputInteraction}
                  placeholder="Write your LinkedIn post content..."
                  className={cn(
                    "mt-1.5 min-h-[150px]",
                    isDisabled && "cursor-not-allowed opacity-60"
                  )}
                  disabled={isDisabled}
                />
              </div>
              <div>
                <Label>Hashtags</Label>
                <Input
                  value={hashtags}
                  onChange={(e) => unlimitedUser && setHashtags(e.target.value)}
                  placeholder="#career #networking #professional"
                  onMouseDown={handleInputInteraction}
                  onFocus={handleInputInteraction}
                  className={cn(
                    "mt-1.5",
                    isDisabled && "cursor-not-allowed opacity-60"
                  )}
                  disabled={isDisabled}
                />
              </div>
            </div>
          </Card>
        );

      case "linkedin-message":
        return (
          <Card className="p-4 border-[#0A66C2] dark:border-[#0A66C2]/50">
            <div className="flex items-center gap-2 mb-3">
              <div className="w-8 h-8 rounded bg-[#0A66C2] flex items-center justify-center text-white font-semibold">
                in
              </div>
              <div className="text-sm text-gray-600 dark:text-gray-400">
                LinkedIn Message
              </div>
            </div>
            <div className="space-y-4">
              <div>
                <Label>Connection Name</Label>
                <Input
                  value={connectionName}
                  onChange={(e) => unlimitedUser && setConnectionName(e.target.value)}
                  placeholder="Enter recipient's name"
                  onMouseDown={handleInputInteraction}
                  onFocus={handleInputInteraction}
                  className={cn(
                    "mt-1.5",
                    isDisabled && "cursor-not-allowed opacity-60"
                  )}
                  disabled={isDisabled}
                />
              </div>
              <div>
                <Label>Message</Label>
                <Textarea
                  ref={emailInputRef}
                  value={content}
                  onChange={(e) => unlimitedUser && setContent(e.target.value)}
                  onMouseDown={handleInputInteraction}
                  onFocus={handleInputInteraction}
                  placeholder="Write your LinkedIn message..."
                  className={cn(
                    "mt-1.5 min-h-[120px]",
                    isDisabled && "cursor-not-allowed opacity-60"
                  )}
                  disabled={isDisabled}
                />
              </div>
            </div>
          </Card>
        );

      case "whatsapp-message":
        return (
          <Card className="p-4 border-[#25D366] dark:border-[#25D366]/50">
            <div className="space-y-4">
              <div>
                <Label>To</Label>
                <Input
                  placeholder="Enter recipient's name"
                  onMouseDown={handleInputInteraction}
                  onFocus={handleInputInteraction}
                  className={cn(
                    "mt-1.5",
                    isDisabled && "cursor-not-allowed opacity-60"
                  )}
                  disabled={isDisabled}
                />
              </div>
              <div>
                <Label>Message</Label>
                <Textarea
                  ref={emailInputRef}
                  value={content}
                  onChange={(e) => unlimitedUser && setContent(e.target.value)}
                  onMouseDown={handleInputInteraction}
                  onFocus={handleInputInteraction}
                  placeholder="Type a message"
                  className={cn(
                    "mt-1.5 min-h-[100px]",
                    isDisabled && "cursor-not-allowed opacity-60"
                  )}
                  disabled={isDisabled}
                />
              </div>
            </div>
          </Card>
        );

      case "slack-message":
        return (
          <Card className="p-4 border-[#4A154B] dark:border-[#4A154B]/50">
            <div className="flex items-center gap-2 mb-3">
              <div className="w-8 h-8 rounded bg-[#4A154B] flex items-center justify-center text-white font-semibold">
                S
              </div>
              <div className="text-sm text-gray-600 dark:text-gray-400">
                Slack Message
              </div>
            </div>
            <div className="space-y-4">
              <div>
                <Label>Channel/Recipient</Label>
                <Input
                  placeholder="Enter channel or recipient name"
                  onMouseDown={handleInputInteraction}
                  onFocus={handleInputInteraction}
                  className={cn(
                    "mt-1.5",
                    isDisabled && "cursor-not-allowed opacity-60"
                  )}
                  disabled={isDisabled}
                />
              </div>
              <div>
                <Label>Message</Label>
                <Textarea
                  ref={emailInputRef}
                  value={content}
                  onChange={(e) => unlimitedUser && setContent(e.target.value)}
                  onMouseDown={handleInputInteraction}
                  onFocus={handleInputInteraction}
                  placeholder="Type your Slack message..."
                  className={cn(
                    "mt-1.5 min-h-[100px]",
                    isDisabled && "cursor-not-allowed opacity-60"
                  )}
                  disabled={isDisabled}
                />
              </div>
            </div>
          </Card>
        );

      case "e-mail":
        return (
          <div className="space-y-4">
            <div>
              <Label>Subject</Label>
              <Input
                placeholder="Enter email subject"
                value={subject}
                onChange={(e) => unlimitedUser && setSubject(e.target.value)}
                onMouseDown={handleInputInteraction}
                onFocus={handleInputInteraction}
                className={cn(
                  "mt-1.5",
                  !unlimitedUser && "cursor-not-allowed opacity-60"
                )}
                disabled={!unlimitedUser}
              />
            </div>
            <div>
              <Label>Content</Label>
              <Textarea
                ref={emailInputRef}
                rows={5}
                placeholder="Write your email content here..."
                value={content}
                onChange={(e) => unlimitedUser && setContent(e.target.value)}
                onMouseDown={handleInputInteraction}
                onFocus={handleInputInteraction}
                  className={cn(
                    "mt-1.5 min-h-[120px]",
                    isDisabled && "cursor-not-allowed opacity-60"
                  )}
                  disabled={isDisabled}
              />
            </div>
          </div>
        );

      default:
        return (
          <Textarea
            ref={emailInputRef}
            value={content}
            onChange={(e) => unlimitedUser && setContent(e.target.value)}
            onMouseDown={handleInputInteraction}
            onFocus={handleInputInteraction}
            rows={5}
              className={cn(
                "w-full min-h-[100px] md:min-h-[120px] p-3 md:p-4 text-sm md:text-base",
                isDisabled && "cursor-not-allowed opacity-60"
              )}
              placeholder={String(t("try-now.placeholder"))}
              disabled={isDisabled}
          />
        );
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <Label className="text-sm md:text-base font-medium">
          {String(t("try-now.copy-current-message"))}
        </Label>
        <Toggle
          variant="outline"
          size="sm"
          pressed={isV2Visible}
          onPressedChange={handleV2Toggle}
          className={cn(
            "relative",
            isDisabled && "hover:opacity-50"
          )}
        >
            {isV2 ? (
              <Undo2 className="h-4 w-4 mr-2" />
            ) : (
              <Sparkles className="h-4 w-4 mr-2" />
            )}
            {isV2 ? "Version 1" : "Version 2"}
          </Toggle>
      </div>
      
      {isV2Visible && !unlimitedUser && (
        <div className="flex justify-center">
          <Button
            variant="outline"
            size="sm"
            className="text-xs md:text-sm text-[#2D6250] font-medium"
            onClick={() => router.push("/pricing")}
          >
            {t("toaster.daily-limit-reached")}
          </Button>
        </div>
      )}
      
      <p className="text-xs md:text-sm text-muted-foreground">
        {String(t("try-now.copy-current-message-2"))}
      </p>

      {isV2Visible ? (
        renderEditor()
      ) : (
        <Textarea
          id="message-input"
          ref={emailInputRef}
          rows={5}
          className="w-full min-h-[100px] md:min-h-[120px] p-3 md:p-4 text-sm md:text-base"
          placeholder={String(t("try-now.placeholder"))}
          onChange={(e) => {
            setContent(e.target.value);
            setEmail(e.target.value);
          }}
          value={content}
        />
      )}
    </div>
  );
}