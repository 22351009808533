import { t } from "i18next";
import Link from "next/link";
import { motion } from "framer-motion";
import { Button } from "../ui/button";
import { Separator } from "../ui/separator";
import { Twitter, Github, MessageSquare, Calculator, FileText, Mic, Shield, Book } from "lucide-react";

export default function Footer() {
  const currentYear = new Date().getFullYear();

  const footerSections = [
    {
      title: String(t("footer.resources")),
      items: [
        { 
          label: String(t("header.pricing")), 
          href: "/pricing", 
          icon: <Calculator className="w-4 h-4" /> 
        },
        { 
          label: String(t("header.dashboard")), 
          href: "/dashboard", 
          icon: <MessageSquare className="w-4 h-4" /> 
        },
        { 
          label: String(t("footer.excel-formula-generator")), 
          href: "/formula-generator", 
          icon: <Calculator className="w-4 h-4" /> 
        },
      ],
    },
    {
      title: String(t("header.message-generator")),
      items: [
        { 
          label: String(t("header.audio-to-text")), 
          href: "/audio-transcriptor", 
          icon: <Mic className="w-4 h-4" /> 
        },
        { 
          label: String(t("header.bg-remover")), 
          href: "/background-remover", 
          icon: <FileText className="w-4 h-4" /> 
        },
        { 
          label: String(t("footer.cover-letter-generator")), 
          href: "/cover-letter", 
          icon: <FileText className="w-4 h-4" /> 
        },
      ],
    },
    {
      title: String(t("footer.help")),
      items: [
        { 
          label: String(t("footer.privacy-policy")), 
          href: "/privacy", 
          icon: <Shield className="w-4 h-4" /> 
        },
        { 
          label: String(t("footer.terms-of-service")), 
          href: "/terms", 
          icon: <Book className="w-4 h-4" /> 
        },
      ],
    },
  ];

  const socialLinks = [
    {
      href: "https://twitter.com/emreisik_",
      icon: <Twitter className="h-5 w-5" />,
      label: "Twitter",
    },
    {
      href: "https://github.com/emreisik95",
      icon: <Github className="h-5 w-5" />,
      label: "GitHub",
    },
  ];

  return (
    <footer className="relative border-t border-[#2D6250]/20">
      {/* Background Effects */}
      <div className="absolute inset-0 pointer-events-none">
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-[#2D6250]/5 to-transparent opacity-20" />
      </div>

      <div className="container mx-auto px-4 py-12 relative">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-12">
          {footerSections.map((section, index) => (
            <motion.div
              key={section.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="space-y-4"
            >
              <h3 className="font-semibold text-[#2D6250]">{section.title}</h3>
              <ul className="space-y-3">
                {section.items.map((item) => (
                  <li key={item.label}>
                    <Button 
                      variant="link" 
                      asChild 
                      className="h-auto p-0 text-muted-foreground hover:text-[#2D6250] transition-colors"
                    >
                      <Link href={item.href} className="flex items-center gap-2">
                        {item.icon}
                        {item.label}
                      </Link>
                    </Button>
                  </li>
                ))}
              </ul>
            </motion.div>
          ))}

          {/* Social Links */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
            className="space-y-4"
          >
            <h3 className="font-semibold text-[#2D6250]">{String(t("footer.support"))}</h3>
            <div className="flex gap-4">
              {socialLinks.map((link) => (
                <Button
                  key={link.label}
                  variant="outline"
                  size="icon"
                  asChild
                  className="border-[#2D6250]/20 hover:bg-[#2D6250]/10 transition-colors"
                >
                  <a
                    href={link.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-muted-foreground hover:text-[#2D6250]"
                  >
                    {link.icon}
                    <span className="sr-only">{link.label}</span>
                  </a>
                </Button>
              ))}
            </div>
          </motion.div>
        </div>

        <Separator className="my-8 bg-[#2D6250]/20" />

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="flex flex-col md:flex-row justify-between items-center gap-4"
        >
          <p className="text-sm text-muted-foreground">
            © {currentYear} Professionalize It To Me. {String(t("footer.all-rights-reserved"))}
          </p>
          <div className="flex items-center gap-2">
            <span className="text-sm text-muted-foreground">{String(t("footer.made-with"))}</span>
            <span className="text-[#2D6250]">♥</span>
          </div>
        </motion.div>
      </div>
    </footer>
  );
}