import { getAuth, signInWithPopup, signOut, AuthProvider } from "firebase/auth";
import { GoogleAuthProvider, GithubAuthProvider } from "firebase/auth";
import Router from "next/router";

export const auth = getAuth();
export const googleProvider = new GoogleAuthProvider();
export const githubProvider = new GithubAuthProvider();

// Add required scopes
googleProvider.addScope('https://www.googleapis.com/auth/userinfo.email');
githubProvider.addScope('repo');

/**
 * Generic function to handle authentication with different providers
 * @param provider - The authentication provider (Google or Github)
 * @returns Returns true if authentication successful, false otherwise
 */
async function signInWithProvider(provider: AuthProvider): Promise<boolean> {
  try {
    const result = await signInWithPopup(auth, provider);
    return !!result;
  } catch (error) {
    console.error('Authentication error:', error);
    return false;
  }
}

export const signInWithGoogle = (): Promise<boolean> => 
  signInWithProvider(googleProvider);

export const signInWithGithub = (): Promise<boolean> => 
  signInWithProvider(githubProvider);

/**
 * Signs out the user and redirects to home page
 * @returns Returns true if sign out successful, false otherwise
 */
export const signOutCompletely = async (): Promise<boolean> => {
  try {
    await signOut(auth);
    await Router.push('/');
    return true;
  } catch (error) {
    console.error('Sign out error:', error);
    await Router.push('/');
    return false;
  }
};