/**
 * Supported currency codes
 */
type CurrencyCode = string;

/**
 * Options for number formatting
 */
interface NumberFormatOptions {
  style: 'currency';
  currency: CurrencyCode;
  currencyDisplay: 'symbol';
}

/**
 * List of zero-decimal currencies
 * https://stripe.com/docs/currencies#zero-decimal
 */
const ZERO_DECIMAL_CURRENCIES = new Set([
  'BIF', 'CLP', 'DJF', 'GNF', 'JPY', 'KMF', 'KRW', 'MGA',
  'PYG', 'RWF', 'UGX', 'VND', 'VUV', 'XAF', 'XOF', 'XPF'
]);

/**
 * Creates a number formatter for a specific currency
 * @param currency - The currency code
 * @returns Intl.NumberFormat instance
 */
const createNumberFormatter = (currency: CurrencyCode): Intl.NumberFormat => {
  const options: NumberFormatOptions = {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol'
  };
  return new Intl.NumberFormat(['en-US'], options);
};

/**
 * Formats an amount for display with proper currency symbol
 * @param amount - The amount to format
 * @param currency - The currency code
 * @returns Formatted amount string
 */
export function formatAmountForDisplay(
  amount: number,
  currency: CurrencyCode,
  includeCurrency: boolean = true
): string {
  try {
    const formatter = createNumberFormatter(currency);
    const formatted = formatter.format(amount);
    if (!includeCurrency) {
      // Remove currency symbol and any leading/trailing whitespace
      return formatted.replace(/[^0-9,.]/g, '').trim();
    }
    return formatted;
  } catch (error) {
    console.error('Error formatting amount for display:', error);
    return `${currency}`;
  }
}

/**
 * Formats an amount for Stripe API (converts to smallest currency unit if needed)
 * @param amount - The amount to format
 * @param currency - The currency code
 * @returns Formatted amount number
 */
export function formatAmountForStripe(
  amount: number,
  currency: CurrencyCode
): number {
  try {
    if (ZERO_DECIMAL_CURRENCIES.has(currency.toUpperCase())) {
      return amount;
    }
    return Math.round(amount * 100);
  } catch (error) {
    console.error('Error formatting amount for Stripe:', error);
    return amount;
  }
}

/**
 * Formats an amount from Stripe API (converts from smallest currency unit if needed)
 * @param amount - The amount to format
 * @param currency - The currency code
 * @returns Formatted amount number
 */
export function formatAmountFromStripe(
  amount: number,
  currency: CurrencyCode
): number {
  try {
    if (ZERO_DECIMAL_CURRENCIES.has(currency.toUpperCase())) {
      return amount;
    }
    return amount / 100;
  } catch (error) {
    console.error('Error formatting amount from Stripe:', error);
    return amount;
  }
}

/**
 * Validates if a currency code is supported by Stripe
 * @param currency - The currency code to validate
 * @returns boolean indicating if currency is valid
 */
export function isValidCurrency(currency: string): boolean {
  try {
    // Test if we can create a formatter with this currency
    createNumberFormatter(currency);
    return true;
  } catch {
    return false;
  }
}
