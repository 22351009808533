import { motion } from "framer-motion";
import { FADE_UP_ANIMATION_VARIANTS } from "../../enums/FramerEnums";
import { Badge } from "../ui/badge";
import { Button } from "../ui/button";
import { Card } from "../ui/card";
import { ArrowRight, Sparkles, Play } from "lucide-react";
import { useGeneratedEmailCount } from "@/hooks/useGeneratedEmailCount";
import { getExamples } from "@/utils/examples";
import { t } from "i18next";

interface MainLeftProps {
  activeSegment?: number;
  onExampleSelect?: (content: string) => void;
}

interface Example {
  id: number;
  title: string;
  content: string;
  image: string;
}

export default function MainLeft({ activeSegment = 1, onExampleSelect }: MainLeftProps) {
  const examples = getExamples(t);
  const currentExamples = examples[activeSegment] || examples[1];
  const { emailCount } = useGeneratedEmailCount();

  const handleExampleClick = (example: Example) => {
    if (onExampleSelect) {
      onExampleSelect(example.content);
      
      // Scroll to input after a small delay to ensure state is updated
      setTimeout(() => {
        const messageInput = document.getElementById('message-input');
        if (messageInput) {
          messageInput.scrollIntoView({ behavior: 'smooth' });
          messageInput.focus();
        }
      }, 100);
    }
  };

  return (
    <div className="space-y-6 sm:space-y-8 px-4 sm:px-6 lg:px-8 lg:pr-8">
      <motion.div 
        initial="hidden" 
        animate="show" 
        viewport={{ once: true }}
        className="space-y-8"
      >
        {/* Badge */}
        <motion.div variants={FADE_UP_ANIMATION_VARIANTS}>
          <Badge 
            variant="outline" 
            className="px-4 py-2 border-[#2D6250]/20 bg-[#2D6250]/5 hover:bg-[#2D6250]/10 transition-colors cursor-pointer"
          >
            <Sparkles className="w-4 h-4 mr-2 text-[#2D6250]" />
            <span className="text-[#2D6250] font-medium">{t("header.new")}</span>
          </Badge>
        </motion.div>

        {/* Title */}
        <motion.div variants={FADE_UP_ANIMATION_VARIANTS} className="space-y-6">
          <h1 className="text-3xl sm:text-4xl font-bold tracking-tight lg:text-5xl xl:text-6xl">
            <span className="block text-gray-900 dark:text-white">
              {t("hero.title")}
            </span>
            <span className="block bg-clip-text text-transparent bg-gradient-to-r from-[#2D6250] via-[#2D6250]/80 to-[#2D6250]">
              {t("hero.subtitle") || "That Connect"}
            </span>
          </h1>
          
          <p className="text-base sm:text-lg text-gray-600 dark:text-gray-300 leading-relaxed max-w-2xl">
            {t("hero.description")}
          </p>
        </motion.div>

        {/* Example Usages */}
        <motion.div variants={FADE_UP_ANIMATION_VARIANTS} className="space-y-4">
          <h3 className="text-lg font-semibold text-[#2D6250]">
            {t("hero.example-usages")}
          </h3>
          <div className="grid gap-4">
            {currentExamples.map((example: Example) => (
              <Card 
                key={example.id}
                className="p-4 bg-white/50 dark:bg-gray-900/50 border-[#2D6250]/10 hover:border-[#2D6250]/20 transition-colors cursor-pointer"
                onClick={() => handleExampleClick(example)}
              >
                <div className="flex items-start gap-4">
                  <div className="flex-1">
                    <h4 className="font-medium text-gray-900 dark:text-gray-100">
                      {example.title}
                    </h4>
                    <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">
                      {example.content}
                    </p>
                  </div>
                  <ArrowRight className="w-5 h-5 text-[#2D6250]" />
                </div>
              </Card>
            ))}
          </div>
        </motion.div>

        {/* CTA Buttons */}
        <motion.div 
          variants={FADE_UP_ANIMATION_VARIANTS}
          className="flex flex-col sm:flex-row gap-4"
        >
          <Button 
            size="lg" 
            className="group relative overflow-hidden bg-[#2D6250] hover:bg-[#2D6250]/90"
            onClick={() => document.getElementById('message-input')?.focus()}
          >
            <span className="relative z-10 flex items-center">
              {t("try-now.get-started")}
              <ArrowRight className="w-4 h-4 ml-2 transition-transform group-hover:translate-x-1" />
            </span>
          </Button>
         {/* <Button 
            size="lg" 
            variant="outline"
            className="group relative overflow-hidden border-[#2D6250]/20 hover:border-[#2D6250]/30 hover:bg-[#2D6250]/5"
          >
            <span className="relative z-10 flex items-center">
              {t("try-now.watch-demo")}
              <div className="relative ml-2 w-5 h-5 rounded-full bg-[#2D6250]/10 flex items-center justify-center">
                <Play className="w-3 h-3 text-[#2D6250]" />
              </div>
            </span>
          </Button>*/
          }
        </motion.div>

        {/* Stats */}
        <motion.div 
          variants={FADE_UP_ANIMATION_VARIANTS}
          className="grid grid-cols-1 sm:grid-cols-3 gap-4"
        >
          {[
            { value: "10K+", label: t("try-now.users") || "Users" },
            { value: emailCount, label: t("try-now.messages") || "Messages" },
            { value: "4.9/5", label: t("try-now.rating") || "Rating" },
          ].map((stat, index) => (
            <div 
              key={index} 
              className="relative group p-4 rounded-lg bg-[#2D6250]/5 hover:bg-[#2D6250]/10 transition-colors"
            >
              <div className="text-2xl font-bold text-[#2D6250]">{stat.value}</div>
              <div className="text-sm text-gray-600 dark:text-gray-400">{stat.label}</div>
            </div>
          ))}
        </motion.div>
      </motion.div>
    </div>
  );
}
