import { TFunction } from "i18next";

export interface ExampleUsage {
  id: number;
  title: string;
  content: string;
  image: string;
}

export function getExamples(t: TFunction): { [key: number]: ExampleUsage[] } {
  return {
    1: [
      {
        id: 1,
        title: t("examples.categories.message-generator.examples.0.title"),
        content: t("examples.categories.message-generator.examples.0.content"),
        image: "/examples/message-1.png",
      },
      {
        id: 2,
        title: t("examples.categories.message-generator.examples.1.title"),
        content: t("examples.categories.message-generator.examples.1.content"),
        image: "/examples/message-2.png",
      },
    ],
    2: [
      {
        id: 1,
        title: t("examples.categories.cover-letter.examples.0.title"),
        content: t("examples.categories.cover-letter.examples.0.content"),
        image: "/examples/cover-1.png",
      },
      {
        id: 2,
        title: t("examples.categories.cover-letter.examples.1.title"),
        content: t("examples.categories.cover-letter.examples.1.content"),
        image: "/examples/cover-2.png",
      },
    ],
    3: [
      {
        id: 1,
        title: t("examples.categories.excel-formula.examples.0.title"),
        content: t("examples.categories.excel-formula.examples.0.content"),
        image: "/examples/excel-1.png",
      },
      {
        id: 2,
        title: t("examples.categories.excel-formula.examples.1.title"),
        content: t("examples.categories.excel-formula.examples.1.content"),
        image: "/examples/excel-2.png",
      },
    ],
    4: [
      {
        id: 1,
        title: t("examples.categories.audio-to-text.examples.0.title"),
        content: t("examples.categories.audio-to-text.examples.0.content"),
        image: "/examples/audio-1.png",
      },
      {
        id: 2,
        title: t("examples.categories.audio-to-text.examples.1.title"),
        content: t("examples.categories.audio-to-text.examples.1.content"),
        image: "/examples/audio-2.png",
      },
    ],
  };
}