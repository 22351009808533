import { useEffect, useState } from "react";
import { Check, ChevronDown } from "lucide-react";
import { Button } from "../ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { cn } from "../../lib/utils";

export type Formula = "Microsoft Excel" | "Google Sheets" | "LibreOffice Calc";

interface FormulaDropDownProps {
  formula: Formula;
  setFormula: (formula: Formula) => void;
}

const formulas: Formula[] = [
  "Microsoft Excel",
  "Google Sheets",
  "LibreOffice Calc",
];

export default function FormulaDropDown({
  formula,
  setFormula,
}: FormulaDropDownProps) {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="outline"
          className={cn(
            "w-full justify-between",
            "bg-background hover:bg-accent",
            "text-foreground",
            "border-input"
          )}
        >
          <span className="font-medium">{formula}</span>
          <ChevronDown className="ml-2 h-4 w-4 opacity-50" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="start"
        className="w-[var(--radix-dropdown-menu-trigger-width)]"
      >
        {formulas.map((formulaOption) => (
          <DropdownMenuItem
            key={formulaOption}
            onClick={() => setFormula(formulaOption)}
            className={cn(
              "flex items-center justify-between",
              formula === formulaOption && "bg-accent"
            )}
          >
            <span className={cn(
              "truncate",
              formula === formulaOption && "font-medium"
            )}>
              {formulaOption}
            </span>
            {formula === formulaOption && (
              <Check className="ml-2 h-4 w-4 text-primary" />
            )}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}