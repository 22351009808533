import { motion } from "framer-motion";
import useMeasure from "react-use-measure";
import { parseContent, formatStructuredContent } from "../../utils/content-parser";
import { Card } from "../ui/card";
import { cn } from "../../lib/utils";
import { MessageType } from "../Dropdowns/MessageTypeDropDown";

interface ResizablePanelProps {
  children: React.ReactNode;
  className?: string;
  messageType?: MessageType;
}

export default function ResizablePanel({ 
  children, 
  className = "",
  messageType = "e-mail"
}: ResizablePanelProps) {
  const [ref, { height }] = useMeasure();

  const renderContent = (content: string) => {
    const parsedContent = parseContent(content);
    const formattedContent = formatStructuredContent(messageType, parsedContent);

    switch (messageType) {
      case "linkedin-post":
        return (
          <Card className="p-4 border-[#0A66C2] dark:border-[#0A66C2]/50">
            <div className="flex items-center gap-2 mb-3">
              <div className="w-8 h-8 rounded bg-[#0A66C2] flex items-center justify-center text-white font-semibold">
                in
              </div>
              <div className="text-sm text-gray-600 dark:text-gray-400">
                LinkedIn Post
              </div>
            </div>
            <div className="prose dark:prose-invert max-w-none">
              {formattedContent.split('\n').map((line, i) => (
                <p key={i} className={cn(
                  "my-2",
                  line.startsWith('#') && "text-xl font-bold text-[#0A66C2]"
                )}>
                  {line.replace(/^# /, '')}
                </p>
              ))}
            </div>
          </Card>
        );

      case "linkedin-message":
        return (
          <Card className="p-4 border-[#0A66C2] dark:border-[#0A66C2]/50">
            <div className="flex items-center gap-2 mb-3">
              <div className="w-8 h-8 rounded bg-[#0A66C2] flex items-center justify-center text-white font-semibold">
                in
              </div>
              <div className="text-sm text-gray-600 dark:text-gray-400">
                LinkedIn Message
              </div>
            </div>
            <div className="prose dark:prose-invert max-w-none">
              {formattedContent.split('\n').map((line, i) => (
                <p key={i} className="my-2">{line}</p>
              ))}
            </div>
          </Card>
        );

      case "slack-message":
        return (
          <Card className="p-4 border-[#4A154B] dark:border-[#4A154B]/50">
            <div className="flex items-center gap-2 mb-3">
              <div className="w-8 h-8 rounded bg-[#4A154B] flex items-center justify-center text-white font-semibold">
                S
              </div>
              <div className="text-sm text-gray-600 dark:text-gray-400">
                Slack Message
              </div>
            </div>
            <div className="prose dark:prose-invert max-w-none">
              {formattedContent.split('\n').map((line, i) => (
                <p key={i} className="my-2">{line}</p>
              ))}
            </div>
          </Card>
        );

      case "whatsapp-message":
        return (
          <Card className="p-4 border-[#25D366] dark:border-[#25D366]/50">
            <div className="flex items-center gap-2 mb-3">
              <div className="w-8 h-8 rounded bg-[#25D366] flex items-center justify-center text-white font-semibold">
                W
              </div>
              <div className="text-sm text-gray-600 dark:text-gray-400">
                WhatsApp Message
              </div>
            </div>
            <div className="prose dark:prose-invert max-w-none">
              {formattedContent.split('\n').map((line, i) => (
                <p key={i} className="my-2">{line}</p>
              ))}
            </div>
          </Card>
        );

      case "e-mail":
        return (
          <Card className="p-4">
            <div className="prose dark:prose-invert max-w-none">
              {formattedContent.split('\n').map((line, i) => (
                <p key={i} className={cn(
                  "my-2",
                  line.startsWith('Subject:') && "font-bold text-lg"
                )}>
                  {line}
                </p>
              ))}
            </div>
          </Card>
        );

      default:
        return (
          <div className="prose dark:prose-invert max-w-none">
            {formattedContent.split('\n').map((line, i) => (
              <p key={i} className="my-2">{line}</p>
            ))}
          </div>
        );
    }
  };

  return (
    <motion.div
      animate={height ? { height } : {}}
      style={height ? { height } : {}}
      className={`relative w-full overflow-hidden ${className}`}
      transition={{ 
        type: "spring",
        bounce: 0.2,
        duration: 0.8
      }}
    >
      <div 
        ref={ref} 
        className={height ? "absolute inset-x-0" : "relative"}
      >
        {typeof children === 'string' ? renderContent(children) : children}
      </div>
    </motion.div>
  );
}
