import { utcToZonedTime } from "date-fns-tz";
import { differenceInMilliseconds } from "date-fns";

interface RemainingTime {
  hours: number;
  minutes: number;
}

/**
 * Calculates the remaining time until 5 PM ET (Eastern Time)
 * If current time is after 5 PM, calculates time until 5 PM next day
 * @returns {RemainingTime} Object containing remaining hours and minutes
 */
export default function getRemainingTime(): RemainingTime {
  try {
    const now = new Date();
    const ET_TIMEZONE = "America/New_York";
    const TARGET_HOUR = 17; // 5 PM

    // Convert current date and time to Eastern Timezone
    const etTime = utcToZonedTime(now, ET_TIMEZONE);

    // Determine if current time is before or after 5 PM ET
    const isBefore5pmET = etTime.getHours() < TARGET_HOUR;

    // Calculate target date (today or tomorrow at 5 PM ET)
    const targetDate = new Date(etTime);
    targetDate.setHours(TARGET_HOUR, 0, 0, 0);

    if (!isBefore5pmET) {
      targetDate.setDate(targetDate.getDate() + 1);
    }

    // Calculate time difference
    const timeDiff = differenceInMilliseconds(targetDate, etTime);
    
    // Convert to hours and minutes
    const hours = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

    return { hours, minutes };
  } catch (error) {
    console.error('Error calculating remaining time:', error);
    // Return default values in case of error
    return { hours: 0, minutes: 0 };
  }
}