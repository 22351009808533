import { t } from "i18next";
import { motion } from "framer-motion";
import { Button } from "../ui/button";
import { Card } from "../ui/card";
import { Badge } from "../ui/badge";
import { cn } from "../../lib/utils";
import { FADE_UP_ANIMATION_VARIANTS } from "../../enums/FramerEnums";
import { MessageSquare, FileText, Calculator, Mic, ChevronRight } from "lucide-react";

// Components
import CoverLetter from "../CoverLetter/CoverLetter";
import ExcelFormula from "../ExcelFormula/ExcelFormula";
import AudioTranscriptor from "../AudioTranscriptor/AudioTranscriptor";
import MessageGenerator from "../MessageGenerator/MessageGenerator";

interface SegmentSelectorProps {
  activeSegment: number;
  onSegmentChange: (segment: number) => void;
}

interface Segment {
  id: number;
  label: string;
  icon: React.ReactNode;
  description: string;
  isFree?: boolean;
}

const segments: Segment[] = [
  { 
    id: 1, 
    label: "header.message-generator",
    icon: <MessageSquare className="w-5 h-5" />,
    description: t("try-now.message-generator-desc")
  },
  { 
    id: 2, 
    label: "header.cover-letter-generator",
    icon: <FileText className="w-5 h-5" />,
    description: t("try-now.cover-letter-desc"),
    isFree: true
  },
  { 
    id: 3, 
    label: "header.excel-formula-generator",
    icon: <Calculator className="w-5 h-5" />,
    description: t("try-now.excel-formula-desc"),
  },
  { 
    id: 4, 
    label: "header.audio-to-text",
    icon: <Mic className="w-5 h-5" />,
    description: t("try-now.audio-to-text-desc")
  },
];

export default function SegmentSelector({ activeSegment, onSegmentChange }: SegmentSelectorProps) {
  const getActiveComponent = () => {
    switch (activeSegment) {
      case 1:
        return <MessageGenerator />;
      case 2:
        return <CoverLetter />;
      case 3:
        return <ExcelFormula />;
      case 4:
        return <AudioTranscriptor />;
      default:
        return null;
    }
  };

  return (
    <div className="space-y-4 sm:space-y-6 px-4 sm:px-0">
      <motion.div 
        className="grid grid-cols-1 sm:grid-cols-2 gap-4"
        initial="hidden"
        animate="show"
        variants={{
          hidden: {},
          show: {
            transition: {
              staggerChildren: 0.1,
              delayChildren: 0.2
            }
          }
        }}
      >
        {segments.map((segment) => (
          <motion.div 
            key={segment.id} 
            className="relative group"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            variants={FADE_UP_ANIMATION_VARIANTS}
          >
            <Button
              variant={activeSegment === segment.id ? "default" : "secondary"}
              className={cn(
                "w-full h-auto py-4 px-6 relative",
                "transition-all duration-200",
                "flex flex-col items-start gap-2",
                "bg-white/50 dark:bg-gray-900/50 backdrop-blur-sm",
                "hover:bg-[#2D6250]/5",
                "border border-gray-200 dark:border-gray-800",
                activeSegment === segment.id && "border-[#2D6250] bg-[#2D6250]/5"
              )}
              onClick={() => onSegmentChange(segment.id)}
            >
              <div className="flex items-center gap-3 w-full">
                <span className={cn(
                  "p-2 rounded-lg transition-colors",
                  activeSegment === segment.id 
                    ? "bg-[#2D6250] text-white" 
                    : "bg-gray-100 dark:bg-gray-800 group-hover:bg-[#2D6250]/10"
                )}>
                  {segment.icon}
                </span>
                <span className="flex-grow text-left font-semibold text-gray-900 dark:text-white">
                  {t(segment.label)}
                </span>
                {segment.isFree ? (
                  <Badge 
                    variant="secondary" 
                    className="bg-[#2D6250] text-white"
                  >
                    {t("hero.free")}
                  </Badge>
                ) : (
                  <ChevronRight className={cn(
                    "w-5 h-5 transition-transform text-[#2D6250]",
                    activeSegment === segment.id && "rotate-90"
                  )} />
                )}
              </div>
              <p className="text-sm text-gray-600 dark:text-gray-400 text-left pl-11">
                {segment.description}
              </p>
            </Button>
          </motion.div>
        ))}
      </motion.div>

      <motion.div 
        variants={FADE_UP_ANIMATION_VARIANTS}
        initial="hidden"
        animate="show"
        className="relative"
      >
        <Card className="p-6 backdrop-blur-sm bg-white/95 dark:bg-gray-900/95 border-[#2D6250]/20 relative z-10">
          <div className="absolute inset-0 bg-gradient-to-tr from-[#2D6250]/5 via-transparent to-[#2D6250]/5 rounded-lg" />
          <div className="relative">
            {getActiveComponent()}
          </div>
        </Card>
      </motion.div>
    </div>
  );
}
