import { useState } from "react";


export default function useLanguage() {
  const [language, setLanguage] = useState<String>(() => {
    // Get language from localStorage or default to 'en'
    if (typeof window !== "undefined") {
      const storedLanguage = localStorage.getItem("i18nextLng");
      return storedLanguage || "en";
    }
    return "en";
  });

  return {
    language,
  };
}