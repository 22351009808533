import { useState } from "react";
import { MessageType } from "../components/Dropdowns/MessageTypeDropDown";
import { Language } from "../components/Dropdowns/LanguageDropDown";
import { Length } from "../components/Dropdowns/LengthDropDown";
import { VibeType } from "../components/Dropdowns/DropDown";

interface UseEmailGenerationProps {
  emailInput: string;
  vibe: VibeType;
  messageType: MessageType;
  language: Language;
  length: Length;
  useEmojis: boolean;
  useGPT4: boolean;
  user: string;
  isV2?: boolean;
}

export default function useEmailGeneration({
  emailInput,
  vibe,
  messageType,
  language,
  length,
  useEmojis,
  useGPT4,
  user,
  isV2 = false,
}: UseEmailGenerationProps) {
  const [generatedEmail, setGeneratedEmail] = useState<string>("");
  const [isDone, setIsDone] = useState(false);

  const createEmail = async () => {
    setGeneratedEmail("");
    setIsDone(false);

    const response = await fetch("/api/generate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: emailInput,
        vibe,
        messageType,
        language,
        length,
        useEmojis,
        useGPT4,
        user: { email: user },
        isV2,
      }),
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    const data = response.body;
    if (!data) {
      return;
    }

    const reader = data.getReader();
    const decoder = new TextDecoder();
    let done = false;

    while (!done) {
      const { value, done: doneReading } = await reader.read();
      done = doneReading;
      const chunkValue = decoder.decode(value);
      setGeneratedEmail((prev) => prev + chunkValue);
    }

    setIsDone(true);
  };

  return {
    createEmail,
    generatedEmail,
    setGeneratedEmail,
    isDone,
  };
}
