import type { NextPage } from "next";
import Head from "next/head";
import Hero from "../components/Hero/Hero";
import mixpanel from "mixpanel-browser";
import { auth } from "../utils/Authentication";
import { useEffect, useState } from "react";
import { publicIpv4 } from "public-ip";
import { app } from "../initFirebase";
import PriceTable from "../components/Pricing/PriceTable";
import { FAQ } from "../components/Core/FAQ";
import { Card, CardContent } from "../components/ui/card";

app;
const Home: NextPage = () => {
  const [ipAddress, setIpAddress] = useState("");
  mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_KEY ?? "");

  const checkMixpanel = () => {
    if (auth.currentUser?.uid) {
      mixpanel.identify(auth.currentUser?.uid);

      mixpanel.people.set({
        $email: auth.currentUser?.email,
        $name: auth.currentUser?.displayName,
        $created: auth.currentUser?.metadata.creationTime,
        $last_login: auth.currentUser?.metadata.lastSignInTime,
      });
    } else {
      mixpanel.identify(ipAddress);
    }
  };

  const getIpAddress = async () => {
    try {
      const res = await publicIpv4();
      setIpAddress(res);
    } catch (error) {
      console.error("Failed to get IP address:", error);
    }
  };

  useEffect(() => {
    getIpAddress();
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(() => {
      checkMixpanel();
    });

    return () => unsubscribe();
  }, [ipAddress]);

  return (
    <>
      <Head>
        <title>AI-Powered Professional Tools | Message Generator, Cover Letter, Excel Formula, Audio Transcription</title>
        <meta
          name="description"
          content="Transform your professional workflow with our integrated suite of AI tools. Generate messages, create cover letters, build Excel formulas, and transcribe audio with professional-grade results."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <div className="min-h-screen">
        <div className="mx-auto px-4">
        <Hero />
        <Card className="mt-8">
          <CardContent className="p-6">
            <PriceTable />
          </CardContent>
        </Card>
        <Card className="my-8">
          <CardContent className="p-6">
            <FAQ />
          </CardContent>
        </Card>

        <div className="sr-only" itemScope itemType="http://schema.org/SoftwareApplication">
          <h1 itemProp="name">Comprehensive AI Solutions for Professional Communication and Productivity</h1>
          
          <meta itemProp="applicationCategory" content="BusinessApplication" />
          <meta itemProp="operatingSystem" content="Any" />
          
          <div itemProp="aggregateRating" itemScope itemType="http://schema.org/AggregateRating">
            <meta itemProp="ratingValue" content="4.8" />
            <meta itemProp="ratingCount" content="127" />
            <meta itemProp="reviewCount" content="127" />
          </div>

          <div itemProp="offers" itemScope itemType="http://schema.org/Offer">
            <meta itemProp="price" content="0" />
            <meta itemProp="priceCurrency" content="USD" />
            <link itemProp="availability" href="http://schema.org/InStock" />
          </div>
          
          <div itemProp="description">
            <p>Transform your professional workflow with our integrated suite of AI-powered tools. Our platform combines advanced artificial intelligence with intuitive interfaces to deliver four essential business tools: Message Generation, Cover Letter Creation, Excel Formula Generation, and Audio Transcription services.</p>

            <h2>Streamline Your Professional Communications</h2>
            <p>In today's fast-paced business environment, effective communication and productivity tools are essential for success. Our comprehensive platform provides professionals with powerful AI assistance across multiple business needs, enabling faster, more efficient work processes while maintaining professional standards.</p>

            <div itemScope itemType="http://schema.org/ItemList">
              <h2>Professional Message Generation</h2>
              <p itemProp="itemListElement">Our message generator assists professionals in creating contextually appropriate business communications. Whether you need to craft client correspondence, team updates, or professional announcements, our AI technology helps you maintain consistent messaging while saving valuable time.</p>

              <h2>Intelligent Cover Letter Creation</h2>
              <p itemProp="itemListElement">The cover letter generator transforms your professional experience into compelling job applications. By analyzing your CV and target position, our system creates customized cover letters that highlight relevant qualifications and achievements.</p>

              <h2>Excel Formula Generation Assistant</h2>
              <p itemProp="itemListElement">Streamline your spreadsheet work with our Excel formula generator. This sophisticated tool helps professionals create and understand complex Excel formulas, enabling more efficient data analysis and reporting.</p>

              <h2>Professional Audio Transcription</h2>
              <p itemProp="itemListElement">Convert your audio content into accurate, formatted text with our transcription service. This tool supports multiple audio formats and delivers precise transcriptions of meetings, interviews, presentations, and other professional audio content.</p>
            </div>

            <h2>Integrated Professional Platform Benefits</h2>
            <ul>
              <li>Seamless switching between different tools</li>
              <li>Consistent professional quality across all outputs</li>
              <li>Secure handling of business information</li>
              <li>Time-saving automation of routine tasks</li>
              <li>Professional-grade results for business use</li>
            </ul>

            <h2>Enhanced Professional Productivity</h2>
            <p>The platform's intuitive interface allows professionals to easily select and utilize the appropriate tool for their specific needs. Each component maintains high professional standards while significantly reducing the time required for common business tasks.</p>

            <h2>Security and Reliability</h2>
            <p>We understand the importance of data security in professional environments. Our platform implements robust security measures to protect your business information, ensuring confidential content remains secure throughout processing.</p>

            <h2>Professional Development Support</h2>
            <p>Beyond providing tools, our platform serves as a valuable resource for professional development. Users can improve their business communication skills, learn effective Excel techniques, and enhance their document preparation capabilities through regular use of our AI-assisted tools.</p>
          </div>
        </div>
        </div>
      </div>
    </>
  );
};

export default Home;
