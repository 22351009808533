import { publicIpv4 } from 'public-ip';
import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';

type UseDailyLimitType = {
  limit: number;
  isLimitLoaded: boolean;
  fetchDailyLimit: () => Promise<void>;
};

export const useDailyLimit = (): UseDailyLimitType => {
  const [limit, setLimit] = useState<number>(0);
  const [isLimitLoaded, setIsLimitLoaded] = useState<boolean>(false);

  const fetchDailyLimit = async () => {
    try {
      const ip = await publicIpv4();
      const response = await fetch("/api/get-daily-limit", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Forwarded-For": ip,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to fetch daily limit');
      }

      const data = await response.json();
      
      if (typeof data.limit !== 'number') {
        throw new Error('Invalid limit value received');
      }

      setLimit(data.limit);
      setIsLimitLoaded(true);
    } catch (error) {
      console.error('Error fetching daily limit:', error);
      toast.error('Failed to fetch daily limit. Please try again later.');
      setLimit(0);
      setIsLimitLoaded(true);
    }
  };

  useEffect(() => {
    fetchDailyLimit();
  }, []);

  return {
    limit,
    isLimitLoaded,
    fetchDailyLimit,
  };
};
