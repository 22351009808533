import { motion } from "framer-motion";
import { t } from "i18next";
import { FADE_UP_ANIMATION_VARIANTS } from "../../enums/FramerEnums";
import { Clock, MessageSquare } from "lucide-react";

interface MessageCounterProps {
  userLoading: boolean;
  isLimitLoaded: boolean;
  unlimitedUser: boolean;
  limit: number;
  hours: number;
  minutes: number;
  className?: string;
}

export default function MessageCounter({
  userLoading,
  isLimitLoaded,
  unlimitedUser,
  limit,
  hours,
  minutes,
  className = "",
}: MessageCounterProps) {
  if (userLoading || !isLimitLoaded) {
    return <LoadingState />;
  }

  if (unlimitedUser) {
    return null;
  }

  const remainingMessages = Math.max(0, limit - 2);
  const progress = Math.min(100, Math.max(0, (limit / 2) * 100));

  return (
    <div className={`space-y-3 p-4 rounded-lg bg-[#2D6250]/5 ${className}`}>
      <div className="flex items-center justify-between text-sm">
        <div className="flex items-center gap-2 text-[#2D6250]">
          <MessageSquare className="w-4 h-4" />
          <span className="font-medium">
            {t("try-now.free-user", { limit })}
          </span>
        </div>
        <div className="flex items-center gap-2 text-muted-foreground">
          <Clock className="w-4 h-4" />
          <span>
            {t("try-now.free-user-time-limit", { hours, minutes })}
          </span>
        </div>
      </div>

      {/* Custom Progress Bar */}
      <div className="h-2 bg-[#2D6250]/20 rounded-full overflow-hidden">
        <div 
          className="h-full bg-[#2D6250] transition-all duration-300 ease-in-out"
          style={{ width: `${progress}%` }}
        />
      </div>
      {limit <= 0 && (
        <motion.p 
          variants={FADE_UP_ANIMATION_VARIANTS}
          initial="hidden"
          animate="show"
          className="text-sm text-[#2D6250] font-medium text-center"
        >
          {t("toaster.daily-limit-reached")}
        </motion.p>
      )}
    </div>
  );
}

function LoadingState() {
  return (
    <div className={`space-y-3 p-4 rounded-lg bg-[#2D6250]/5`}>
      <div className="flex items-center justify-between text-sm">
        <div className="flex items-center gap-2">
          <motion.div 
            className="w-4 h-4 bg-gray-300 dark:bg-gray-700 rounded-full"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.3 }}
          />
          <motion.div 
            className="w-24 h-4 bg-gray-300 dark:bg-gray-700 rounded-full"
            initial={{ scaleX: 0 }}
            animate={{ scaleX: 1 }}
            transition={{ duration: 0.5, delay: 0.1 }}
          />
        </div>
        <div className="flex items-center gap-2">
          <motion.div 
            className="w-4 h-4 bg-gray-300 dark:bg-gray-700 rounded-full"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.3, delay: 0.2 }}
          />
          <motion.div 
            className="w-20 h-4 bg-gray-300 dark:bg-gray-700 rounded-full"
            initial={{ scaleX: 0 }}
            animate={{ scaleX: 1 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          />
        </div>
      </div>
      <motion.div 
        className="h-2 bg-gray-300 dark:bg-gray-700 rounded-full"
        initial={{ scaleX: 0 }}
        animate={{ scaleX: 1 }}
        transition={{ duration: 0.5, delay: 0.4 }}
      />
    </div>
  );
}