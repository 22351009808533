interface ParsedContent {
  subject?: string;
  content: string;
  headline?: string;
  hashtags?: string;
  connectionName?: string;
}

export function parseContent(text: string): ParsedContent {
  const result: ParsedContent = {
    content: text
  };

  // Parse email subject
  const subjectMatch = text.match(/^Subject:\s*([^\n]+)/);
  if (subjectMatch) {
    result.subject = subjectMatch[1].trim();
    result.content = text.replace(/^Subject:\s*[^\n]+\n+/, '').trim();
  }

  // Parse LinkedIn post
  const headlineMatch = text.match(/^Headline:\s*([^\n]+)/);
  if (headlineMatch) {
    result.headline = headlineMatch[1].trim();
    const withoutHeadline = text.replace(/^Headline:\s*[^\n]+\n+/, '');
    
    const hashtagsMatch = withoutHeadline.match(/Hashtags:\s*([^\n]+)$/);
    if (hashtagsMatch) {
      result.hashtags = hashtagsMatch[1].trim();
      result.content = withoutHeadline
        .replace(/^Content:\s*\n/, '')
        .replace(/\nHashtags:\s*[^\n]+$/, '')
        .trim();
    }
  }

  // Parse LinkedIn message
  const toMatch = text.match(/^To:\s*([^\n]+)/);
  if (toMatch) {
    result.connectionName = toMatch[1].trim();
    result.content = text.replace(/^To:\s*[^\n]+\n+/, '').trim();
  }

  return result;
}

export function formatStructuredContent(type: string, content: ParsedContent): string {
  switch (type) {
    case "linkedin-post":
      return `${content.headline ? `# ${content.headline}\n\n` : ''}${content.content}${content.hashtags ? `\n\n${content.hashtags}` : ''}`;
    
    case "linkedin-message":
      return `${content.connectionName ? `To: ${content.connectionName}\n\n` : ''}${content.content}`;
    
    case "e-mail":
      return `${content.subject ? `Subject: ${content.subject}\n\n` : ''}${content.content}`;
    
    default:
      return content.content;
  }
}