import { Fragment, useRef, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { Check, ChevronsUpDown } from "lucide-react";
import { t } from "i18next";
import { PortalContainer } from "../ui/portal-container";

export const languages = [
  "english",
  "spanish",
  "french",
  "german",
  "italian",
  "japanese",
  "korean",
  "chinese",
  "dutch",
  "portuguese",
  "turkish",
  "arabic",
  "vietnamese"
] as const;

export type Language = (typeof languages)[number];

interface LanguageDropDownProps {
  language: Language;
  setLanguage: (language: Language) => void;
}

export default function LanguageDropDown({
  language,
  setLanguage,
}: LanguageDropDownProps) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative mt-1 w-full cursor-pointer">
      <Listbox value={language} onChange={setLanguage} as="div">
        <Listbox.Button 
          ref={buttonRef}
          onClick={() => setIsOpen(!isOpen)}
          className="relative w-full cursor-default rounded-lg bg-white dark:bg-slate-600 py-2 pl-3 pr-10 text-left border border-gray-300 dark:border-gray-500 focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
        >
          <span className="block truncate dark:text-slate-300">
            {t(`dropdown.${language.toLowerCase()}`)}
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronsUpDown
              className="h-4 w-4 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>

        {isOpen && (
          <PortalContainer>
            <Transition
              show={isOpen}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options 
                static
                className="fixed mt-1 max-h-60 overflow-auto rounded-md bg-white dark:bg-slate-600 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                style={{
                  width: buttonRef.current?.offsetWidth,
                  top: buttonRef.current?.getBoundingClientRect().bottom ?? 0,
                  left: buttonRef.current?.getBoundingClientRect().left ?? 0,
                }}
              >
                {languages.map((lang) => (
                  <Listbox.Option
                    key={lang}
                    value={lang}
                    onClick={() => setIsOpen(false)}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active
                          ? "bg-amber-100 dark:bg-slate-500 text-amber-900 dark:text-slate-300"
                          : "text-gray-900 dark:text-slate-300"
                      }`
                    }
                  >
                    {({ selected }) => (
                      <div>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {t(`dropdown.${lang.toLowerCase()}`)}
                        </span>
                        {selected && (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                            <Check className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </PortalContainer>
        )}
      </Listbox>
    </div>
  );
}