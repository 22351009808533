import { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import toast, { Toaster } from "react-hot-toast";
import { t } from "i18next";
import { auth } from "../../utils/Authentication";
import { publicIpv4 } from "public-ip";
import { sendFormula } from "../../utils/main-functionality";
import { FADE_DOWN_ANIMATION_VARIANTS } from "../../enums/FramerEnums";
import { Marked } from "@ts-stack/markdown";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import { Button } from "../ui/button";
import { Label } from "../ui/label";
import { Textarea } from "../ui/textarea";
import { Copy, Calculator } from "lucide-react";
import LoadingDots from "../Core/LoadingDots";
import ResizablePanel from "../MessageGenerator/ResizablePanel";
import FormulaDropDown, { Formula } from "../Dropdowns/FormulaDropdown";
import LanguageDropDown, { Language } from "../Dropdowns/LanguageDropDown";
import SignInPopup from "../Core/SignInPopup";

export default function ExcelFormula() {
  const [generatedFormula, setGeneratedFormula] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [prompt, setPrompt] = useState<string>("");
  const messageRef = useRef<HTMLHeadingElement>(null);
  const [formula, setFormula] = useState<Formula>("Microsoft Excel");
  const formulaInputRef = useRef<HTMLTextAreaElement>(null);
  const [isUnlimited, setUnlimitedUser] = useState(false);
  const [ipAddress, setIpAddress] = useState<string>("");
  const [language, setLanguage] = useState<Language>("english");
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);

  useEffect(() => {
    // Check for example data in localStorage
    const exampleData = localStorage.getItem("example-usage");
    if (exampleData) {
      const data = JSON.parse(exampleData);
      if (data.formula) {
        setPrompt(data.formula);
        if (formulaInputRef.current) {
          formulaInputRef.current.value = data.formula;
        }
      }
      // Clear the example data after using it
      localStorage.removeItem("example-usage");
    }
  }, []);

  const generateFormula = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (!auth.currentUser) {
      toast.error(String(t("toaster.sign-up-for-use-this-feature")));
      setIsPopupOpen(true);
      return;
    }

    if (prompt.length === 0) {
      toast.error(String(t("toaster.enter-a-prompt")));
      return;
    }
    if (prompt.length > 1000) {
      toast.error(String(t("toaster.prompt-too-long")));
      return;
    }

    setLoading(true);
    setGeneratedFormula("");

    try {
      const response = await fetch("/api/formula-generator", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          formulaType: formula,
          prompt,
          language,
          user: auth.currentUser,
          ipAddress,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to generate formula");
      }

      const data = response.body;
      if (!data) return;

      const reader = data.getReader();
      const decoder = new TextDecoder();
      let done = false;

      while (!done) {
        const { value, done: doneReading } = await reader.read();
        done = doneReading;
        const chunkValue = decoder.decode(value);
        setGeneratedFormula((prev) => prev + chunkValue);
      }
    } catch (error) {
      toast.error(String(t("toaster.error-occurred")));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (generatedFormula.includes("[DONE]")) {
      setGeneratedFormula(generatedFormula.replace("[DONE]", ""));
      if (auth.currentUser?.email) {
        sendFormula(prompt, generatedFormula, ipAddress, auth.currentUser);
      } else {
        sendFormula(prompt, generatedFormula, ipAddress);
      }
    }
  }, [generatedFormula]);

  useEffect(() => {
    const init = async () => {
      const ip = await publicIpv4();
      setIpAddress(ip);
      
      if (auth.currentUser?.email) {
        const response = await fetch("/api/user/type", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ user: auth.currentUser }),
        });
        const { isUnlimitedUser } = await response.json();
        setUnlimitedUser(isUnlimitedUser);
      }
    };

    init();
  }, []);

  return (
    <motion.div
      animate={{
        marginTop: ["-200px", "0px"],
        opacity: [0, 1],
        xHeight: [0, "auto"],
      }}
      viewport={{ once: true }}
      className="xl:col-span-1"
      variants={FADE_DOWN_ANIMATION_VARIANTS}
    >
      <SignInPopup isOpen={isPopupOpen} setIsOpen={setIsPopupOpen} />
      <div className="container mx-auto px-4 py-8">
        <Card className="border-[#2D6250]/20">
          <CardHeader>
            <CardTitle className="flex items-center gap-2 text-[#2D6250]">
              <Calculator className="w-5 h-5" />
              {String(t("excel-formula-generator.title"))}
            </CardTitle>
          </CardHeader>
          <CardContent className="space-y-6">
            <div className="space-y-4">
              <div>
                <Label>{String(t("excel-formula-generator.first-step"))}</Label>
                <FormulaDropDown
                  formula={formula}
                  setFormula={setFormula}
                />
              </div>

              <div>
                <Label>{String(t("excel-formula-generator.second-step"))}</Label>
                <LanguageDropDown
                  language={language}
                  setLanguage={setLanguage}
                />
              </div>

              <div>
                <Textarea
                  ref={formulaInputRef}
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                  rows={12}
                  className="w-full resize-none bg-[#2D6250]/5 focus:bg-[#2D6250]/10 border-[#2D6250]/20 focus:border-[#2D6250]/30 transition-colors"
                  placeholder={String(t("excel-formula-generator.placeholder"))}
                />
              </div>

              <Button
                onClick={generateFormula}
                className="w-full bg-[#2D6250] hover:bg-[#2D6250]/90"
                disabled={loading}
              >
                {loading ? (
                  <LoadingDots color="white" style="large" />
                ) : (
                  <>
                    <Calculator className="w-4 h-4 mr-2" />
                    {String(t("excel-formula-generator.submit"))}
                  </>
                )}
              </Button>
            </div>

            <ResizablePanel>
              <AnimatePresence mode="wait">
                <motion.div className="space-y-4">
                  {generatedFormula && (
                    <>
                      <div className="text-center space-y-2">
                        <h2 className="text-2xl font-bold text-[#2D6250]" ref={messageRef}>
                          {String(t("excel-formula-generator.here-it-is"))}
                        </h2>
                        <p className="text-muted-foreground">
                          {String(t("excel-formula-generator.answer-description"))}
                        </p>
                      </div>

                      <Card 
                        className="relative border-[#2D6250]/20 hover:bg-[#2D6250]/5 transition-colors cursor-copy group"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            String(generatedFormula).replace(/<br\s*[\/]?>/gi, "\n")
                          );
                          toast(String(t("toaster.copied-to-clipboard")), {
                            icon: "✂️",
                          });
                        }}
                      >
                        <CardContent className="pt-6">
                          <div
                            className="prose dark:prose-invert max-w-none"
                            dangerouslySetInnerHTML={{
                              __html: Marked.parse(
                                String(generatedFormula).replace(/<br\s*[\/]?>/gi, "\n")
                              ),
                            }}
                          />
                          <Button
                            variant="ghost"
                            size="icon"
                            className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity"
                          >
                            <Copy className="w-4 h-4" />
                          </Button>
                        </CardContent>
                      </Card>
                    </>
                  )}
                </motion.div>
              </AnimatePresence>
            </ResizablePanel>
          </CardContent>
        </Card>
        <Toaster />
      </div>
    </motion.div>
  );
}
