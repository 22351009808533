import { useState } from "react";
import { t } from "i18next";
import { Sparkles } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import { Button } from "../ui/button";
import { motion } from "framer-motion";
import { ProFeature, proFeatures } from "./ProFeatureContext";

interface ProFeatureUpgradeDialogProps {
  feature: ProFeature;
  onUpgrade?: () => void;
  children: React.ReactNode;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

const featureVariants = {
  hidden: { opacity: 0, x: -20 },
  visible: (i: number) => ({
    opacity: 1,
    x: 0,
    transition: {
      delay: i * 0.1,
    },
  }),
};

export default function ProFeatureUpgradeDialog({
  feature,
  onUpgrade,
  children,
  open,
  onOpenChange
}: ProFeatureUpgradeDialogProps) {
  const [internalOpen, setInternalOpen] = useState(false);
  const isControlled = open !== undefined;
  const isOpen = isControlled ? open : internalOpen;
  const handleOpenChange = isControlled ? onOpenChange : setInternalOpen;

  const handleUpgrade = () => {
    handleOpenChange?.(false);
    onUpgrade?.();
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>
        {children}
      </DialogTrigger>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2 text-[#2D6250]">
            <Sparkles className="w-5 h-5" />
            {String(t(proFeatures[feature].title))}
          </DialogTitle>
          <DialogDescription>
            {String(t(proFeatures[feature].description))}
          </DialogDescription>
        </DialogHeader>
        <div className="space-y-4">
          <div className="grid gap-2">
            {proFeatures[feature].features.map((feat, index) => (
              <motion.div
                key={feat}
                custom={index}
                initial="hidden"
                animate="visible"
                variants={featureVariants}
                className="flex items-center gap-2 text-sm"
              >
                <div className="flex-shrink-0 w-5 h-5 rounded-full bg-[#2D6250]/10 flex items-center justify-center">
                  <Sparkles className="w-3 h-3 text-[#2D6250]" />
                </div>
                <span>{feat}</span>
              </motion.div>
            ))}
          </div>
          <div className="flex justify-end gap-3">
            <Button
              variant="outline"
              onClick={() => handleOpenChange?.(false)}
            >
              {String(t("email-editor.pro-features.maybe-later"))}
            </Button>
            <Button
              onClick={handleUpgrade}
              className="bg-[#2D6250] hover:bg-[#2D6250]/90"
            >
              <Sparkles className="w-4 h-4 mr-2" />
              {String(t("email-editor.pro-features.upgrade-now"))}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}