import { t } from "i18next";
import { motion } from "framer-motion";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../ui/accordion";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import { Badge } from "../ui/badge";
import { HelpCircle, Plus } from "lucide-react";

interface FAQItem {
  questionKey: string;
  answerKey: string;
}

const faqs: FAQItem[] = [
  {
    questionKey: "faq.what-is.question",
    answerKey: "faq.what-is.answer"
  },
  {
    questionKey: "faq.how-works.question",
    answerKey: "faq.how-works.answer"
  },
  {
    questionKey: "faq.languages.question",
    answerKey: "faq.languages.answer"
  },
  {
    questionKey: "faq.data-security.question",
    answerKey: "faq.data-security.answer"
  },
  {
    questionKey: "faq.free-trial.question",
    answerKey: "faq.free-trial.answer"
  },
  {
    questionKey: "faq.premium.question",
    answerKey: "faq.premium.answer"
  }
];

export function FAQ() {
  return (
    <section className="py-12 sm:py-16 lg:py-20 relative">
      <div className="container mx-auto px-4">
        {/* Background Effects */}
        <div className="absolute inset-0 pointer-events-none">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-[#2D6250]/5 via-transparent to-transparent opacity-50" />
          <div className="absolute top-1/4 -left-1/4 w-1/2 h-1/2 bg-[#2D6250]/10 rounded-full blur-3xl" />
          <div className="absolute bottom-1/4 -right-1/4 w-1/2 h-1/2 bg-[#2D6250]/10 rounded-full blur-3xl" />
        </div>

        {/* Content */}
        <div className="relative">
          {/* Header */}
          <div className="text-center space-y-4 mb-12">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="flex items-center justify-center gap-2 mb-4"
            >
              <Badge 
                variant="secondary" 
                className="px-4 py-1 bg-[#2D6250]/10 text-[#2D6250] border-0"
              >
                <HelpCircle className="w-4 h-4 mr-1" />
                {t("faq.help-center")}
              </Badge>
            </motion.div>
            
            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.1 }}
              className="text-3xl font-bold sm:text-4xl xl:text-5xl"
            >
              {t("faq.title")}
            </motion.h2>
          </div>

          {/* FAQ Card */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <Card className="bg-white/95 dark:bg-gray-900/95 backdrop-blur-sm border-[#2D6250]/20">
              <CardContent className="p-6">
                <Accordion type="single" collapsible className="w-full">
                  {faqs.map((faq, index) => (
                    <AccordionItem 
                      key={index} 
                      value={`item-${index}`}
                      className="border-[#2D6250]/20"
                    >
                      <AccordionTrigger className="text-left hover:text-[#2D6250] transition-colors">
                        <span className="text-lg font-medium">
                          {t(faq.questionKey)}
                        </span>
                      </AccordionTrigger>
                      <AccordionContent className="text-muted-foreground">
                        {t(faq.answerKey)}
                      </AccordionContent>
                    </AccordionItem>
                  ))}
                </Accordion>
              </CardContent>
            </Card>
          </motion.div>
        </div>
      </div>
    </section>
  );
}

export default FAQ;