import { useEffect, useState, forwardRef } from 'react';
import { createPortal } from 'react-dom';

interface PortalContainerProps {
  children: React.ReactNode;
}

export const PortalContainer = forwardRef<HTMLDivElement, PortalContainerProps>(
  ({ children }, ref) => {
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
      setMounted(true);
      return () => setMounted(false);
    }, []);

    if (!mounted) return null;

    return createPortal(
      <div ref={ref} className="relative z-[9999]">
        {children}
      </div>,
      document.body
    );
  }
);

PortalContainer.displayName = 'PortalContainer';