import { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import toast, { Toaster } from "react-hot-toast";
import { t } from "i18next";
import { useS3Upload } from "../../hooks/useS3Upload";
import { publicIpv4 } from "public-ip";
import { auth } from "../../utils/Authentication";
import { FADE_DOWN_ANIMATION_VARIANTS } from "../../enums/FramerEnums";
import { useAudioTranscription } from "../../hooks/useAudioTranscription";
import useMixpanel from "../../hooks/useMixPanel";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import { Button } from "../ui/button";
import { Label } from "../ui/label";
import { Checkbox } from "../ui/checkbox";
import { Copy, FileText, Mic, Upload } from "lucide-react";
import LoadingDots from "../Core/LoadingDots";
import ResizablePanel from "../MessageGenerator/ResizablePanel";
import { CreditDisplay } from "../Transcription/CreditDisplay";
import Dropzone, { DropzoneState } from "react-dropzone";

export default function AudioTranscriptor() {
  const { isUploadLoading, s3Url, uploadError, uploadToS3 } = useS3Upload();
  const [translateChecked, setTranslateChecked] = useState(false);
  const [srtRequested, setSrtRequested] = useState(false);
  const [clientIp, setClientIp] = useState<string | null>(null);
  const [unlimitedUser, setUnlimitedUser] = useState(false);
  const [dropText, setDropText] = useState(String(t("audio-transcriptor.drop-text")));
  const { track } = useMixpanel(process.env.NEXT_PUBLIC_MIXPANEL_KEY ?? "");
  const { isLoading, response, transcribeAudio } = useAudioTranscription();

  const cleanFileName = (fileName: string) => {
    const [cleanName] = fileName.split("?");
    return cleanName;
  };

  const handleDrop = async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];

    if (file.size > 3145728) {
      toast(t("audio-transcriptor.too-large"), { icon: "⚠️" });
      return;
    }

    if (
      file.type.includes("audio") &&
      file.name.match(/\.(mp3|mp4|m4a|wmv|wav|ogg|flac)$/i)
    ) {
      await uploadToS3(file, "transcription");
      const ip = await publicIpv4();
      setClientIp(ip);
    } else {
      toast.dismiss();
      toast(t("audio-transcriptor.unsupported-file"), { icon: "⚠️" });
    }
  };

  useEffect(() => {
    const fetchClientIp = async () => {
      try {
        const ip = await publicIpv4();
        setClientIp(ip);
      } catch {
        setTimeout(fetchClientIp, 1000);
      }
    };

    fetchClientIp();

    auth.onAuthStateChanged(async (user) => {
      if (user?.email) {
        const response = await fetch("/api/user/type", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ user }),
        });
        const { isUnlimitedUser } = await response.json();
        setUnlimitedUser(Boolean(isUnlimitedUser));
      }
    });
  }, []);

  useEffect(() => {
    const handleTranscription = async () => {
      if (!clientIp || !s3Url) return;

      const isDailyLimitExceeded = await fetch("/api/transcription/check-limit", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ip: clientIp }),
      }).then(res => res.json()).then(data => data.exceeded);

      if (isDailyLimitExceeded && !unlimitedUser) {
        toast.error(t("toaster.daily-limit-reached"));
        return;
      }

      transcribeAudio(s3Url, translateChecked, srtRequested);
      track("Audio Transcription", {
        "Audio Transcription": "Success",
        url: s3Url,
        transcription: response?.text ?? "",
      });
    };

    handleTranscription();
  }, [s3Url]);

  const renderDropzone = (state: DropzoneState) => {
    const { getRootProps, getInputProps, isDragActive } = state;
    return (
      <div
        {...getRootProps()}
        className={`
          cursor-pointer border-2 border-dashed rounded-lg p-6
          hover:border-[#2D6250]/30 hover:bg-[#2D6250]/5
          transition-colors duration-200
          flex flex-col items-center justify-center gap-4
          ${isDragActive ? "border-[#2D6250]/50 bg-[#2D6250]/10" : "border-[#2D6250]/20"}
        `}
      >
        <input {...getInputProps()} />
        <Upload className="w-8 h-8 text-[#2D6250]" />
        <p className="text-center text-sm text-muted-foreground">
          {isDragActive 
            ? String(t("audio-transcriptor.drop-your-audio-file-here"))
            : dropText
          }
        </p>
      </div>
    );
  };

  return (
    <motion.div
      animate={{
        marginTop: ["-200px", "0px"],
        opacity: [0, 1],
        xHeight: [0, "auto"],
      }}
      viewport={{ once: true }}
      className="xl:col-span-1"
      variants={FADE_DOWN_ANIMATION_VARIANTS}
    >
      <div className="container mx-auto px-4 py-8">
        <Card className="border-[#2D6250]/20">
          <CardHeader>
            <CardTitle className="flex items-center gap-2 text-[#2D6250]">
              <Mic className="w-5 h-5" />
              {t("audio-transcriptor.title")}
            </CardTitle>
          </CardHeader>
          <CardContent className="space-y-6">
            {clientIp && !unlimitedUser && <CreditDisplay ip={clientIp} />}

            {!isLoading && !isUploadLoading && (
              <div className="space-y-4">
                <div>
                  <Label>{t("audio-transcriptor.additional-information")}</Label>
                  <Dropzone
                    onDrop={handleDrop}
                    accept={{
                      "audio/*": [".mp3", ".mp4", ".m4a", ".wmv", ".wav", ".ogg", ".flac"],
                    }}
                    onDropRejected={() => {
                      toast(t("audio-transcriptor.unsupported-file"), {
                        icon: "⚠️",
                      });
                    }}
                  >
                    {renderDropzone}
                  </Dropzone>
                </div>

                <Card className="border-[#2D6250]/20">
                  <CardContent className="pt-6">
                    <p className="text-sm font-medium text-center text-muted-foreground mb-4">
                      {t("audio-transcriptor.config")}
                    </p>
                    <div className="flex flex-wrap gap-6 justify-center">
                      <div className="flex items-center space-x-2">
                        <Checkbox
                          id="translate"
                          checked={translateChecked}
                          onCheckedChange={(checked) => setTranslateChecked(!!checked)}
                          className="border-[#2D6250]/20 data-[state=checked]:bg-[#2D6250] data-[state=checked]:border-[#2D6250]"
                        />
                        <Label htmlFor="translate" className="text-sm">
                          {t("audio-transcriptor.translate")}
                        </Label>
                      </div>

                      <div className="flex items-center space-x-2">
                        <Checkbox
                          id="srt"
                          checked={srtRequested}
                          onCheckedChange={(checked) => setSrtRequested(!!checked)}
                          className="border-[#2D6250]/20 data-[state=checked]:bg-[#2D6250] data-[state=checked]:border-[#2D6250]"
                        />
                        <Label htmlFor="srt" className="text-sm">
                          {t("audio-transcriptor.srt")}
                        </Label>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            )}

            {(isLoading || isUploadLoading) && (
              <Button disabled className="w-full">
                {isLoading ? (
                  <>
                    {t("audio-transcriptor.processing")}
                    <LoadingDots color="white" style="large" />
                  </>
                ) : (
                  <>
                    {t("audio-transcriptor.uploading")}
                    <LoadingDots color="white" style="large" />
                  </>
                )}
              </Button>
            )}

            {response?.srtUrl && (
              <Button
                variant="outline"
                className="w-full border-[#2D6250]/20 hover:bg-[#2D6250]/5"
                onClick={async () => {
                  try {
                    const filename = decodeURIComponent(
                      cleanFileName(response.srtUrl?.split("/").pop() || "")
                    );
                    const res = await fetch(response.srtUrl?.toString() || "");
                    const blob = await res.blob();
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", filename);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  } catch (error) {
                    console.error("Error downloading file:", error);
                  }
                }}
              >
                {t("audio-transcriptor.srt")}
              </Button>
            )}

            <ResizablePanel>
              <AnimatePresence mode="wait">
                <motion.div className="space-y-4">
                  {response && !response?.srtUrl && (
                    <Card 
                      className="relative border-[#2D6250]/20 hover:bg-[#2D6250]/5 transition-colors cursor-copy group"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          String(response.text).replace(/<br\s*[\/]?>/gi, "\n")
                        );
                        toast(t("audio-transcriptor.copy"), { icon: "✂️" });
                      }}
                    >
                      <CardContent className="pt-6">
                        <div className="prose dark:prose-invert max-w-none">
                          <p>{response.text}</p>
                        </div>
                        <Button
                          variant="ghost"
                          size="icon"
                          className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity"
                        >
                          <Copy className="w-4 h-4" />
                        </Button>
                      </CardContent>
                    </Card>
                  )}
                </motion.div>
              </AnimatePresence>
            </ResizablePanel>
          </CardContent>
        </Card>
        <Toaster />
      </div>
    </motion.div>
  );
}
