import { useEffect, useState, useMemo } from "react";
import { motion } from "framer-motion";
import { t } from "i18next";
import toast from "react-hot-toast";
import { publicIpv4 } from "public-ip";
import { useRouter } from "next/router";
import { auth } from "../../utils/Authentication";
import { getProducts, createCheckoutSession } from "../../utils/main-functionality";
import { formatAmountForDisplay } from "../../utils/stripe-helpers";
import SignInPopup from "../Core/SignInPopup";
import { Card } from "../ui/card";
import { Button } from "../ui/button";
import { Badge } from "../ui/badge";
import { Skeleton } from "../ui/skeleton";
import { cn } from "../../lib/utils";
import { Check, Sparkles, Zap, Clock, Info, Infinity } from "lucide-react";

interface CurrencyOption {
  unit_amount: number;
  tax_behavior?: string;
  currency: string;
}

interface Price {
  id: string;
  type: string;
  currency: string;
  currency_options: {
    [key: string]: CurrencyOption;
  };
  unit_amount: number;
}

interface BaseStripeProduct {
  id: string;
  name: string;
  prices: Price[];
  active: boolean;
  description?: string;
}

interface ExtendedStripeProduct extends BaseStripeProduct {
  originalAmount?: number;
}

const FADE_UP_ANIMATION_VARIANTS = {
  hidden: { opacity: 0, y: 10 },
  show: { opacity: 1, y: 0, transition: { type: "spring" } },
};

const getCurrencySymbol = (currency: string) => {
  const formatter = new Intl.NumberFormat('en', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return formatter.format(0).replace(/\d/g, '').trim();
};

export default function PriceTable() {
  const [isUnlimited, setUnlimitedUser] = useState(false);
  const [ipAddress, setIpAddress] = useState<string>("");
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [products, setProducts] = useState<ExtendedStripeProduct[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currency, setCurrency] = useState<string>("TRY");
  const campaignEndDate = useMemo(() => "February 15, 2024", []);
  const router = useRouter();

  const generateFormula = async (priceId: string, type: string) => {
    if (!auth.currentUser) {
      const session = await createCheckoutSession(priceId, "", type, "bH6sV0L6");
      if (session?.url) {
        router.push(session.url);
      } else {
        toast.error(t("toaster.error-occurred"));
      }
      return;
    }

    const session = await createCheckoutSession(
      priceId, 
      auth.currentUser.email ?? "", 
      type, 
      "bH6sV0L6"
    );
    
    if (session?.url) {
      router.push(session.url);
    } else {
      toast.error(t("toaster.error-occurred"));
    }
  };

  const isUnlimitedUser = async () => {
    try {
      const ip = await publicIpv4();
      setIpAddress(ip);
      
      if (auth.currentUser?.email) {
        const response = await fetch("/api/user/type", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ user: auth.currentUser }),
        });

        const { isUnlimitedUser } = await response.json();
        setUnlimitedUser(isUnlimitedUser);
      }
    } catch (error) {
      console.error('Error checking unlimited user status:', error);
      setUnlimitedUser(false);
    }
  };

  const getCurrency = async () => {
    try {
      const ip = await publicIpv4();
      setIpAddress(ip);
      
      const response = await fetch("/api/get-currency", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ipAddress: ip }),
      });

      const { currency: detectedCurrency } = await response.json();
      setCurrency(detectedCurrency?.toUpperCase() || "TRY");
    } catch (error) {
      console.error('Error getting currency:', error);
      setCurrency("TRY");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      
      try {
        await Promise.all([isUnlimitedUser(), getCurrency()]);
        
        const fetchedProducts = await getProducts(currency);
        const currencyKey = currency.toLowerCase();
        
        const processedProducts = (fetchedProducts as unknown as BaseStripeProduct[])
          .filter((product): product is BaseStripeProduct => 
            product.active && (
              product.name.includes("Membership") || 
              product.name.includes("Unlimited") || 
              product.name.includes("Lifetime")
            )
          )
          .map(product => {
            const price = product.prices[0];
            if (!price?.currency_options?.[currencyKey]) return null;

            const unitAmount = price.currency_options[currencyKey].unit_amount;
            if (!unitAmount) return null;

            const isUnlimitedPlan = product.name.toLowerCase().includes("unlimited");
            const shouldApplyDiscount = isUnlimitedPlan && !isUnlimited;

            const processedProduct: ExtendedStripeProduct = {
              ...product,
              originalAmount: shouldApplyDiscount ? unitAmount : undefined,
              prices: [{
                ...price,
                currency_options: {
                  [currencyKey]: {
                    unit_amount: shouldApplyDiscount ? Math.floor(unitAmount * 0.5) : unitAmount,
                    currency: currencyKey
                  }
                }
              }]
            };

            return processedProduct;
          })
          .filter((product): product is ExtendedStripeProduct => product !== null)
          .sort((a, b) => {
            const aAmount = a.prices[0]?.currency_options?.[currencyKey]?.unit_amount ?? 0;
            const bAmount = b.prices[0]?.currency_options?.[currencyKey]?.unit_amount ?? 0;
            return aAmount - bAmount;
          });

        setProducts(processedProducts);
      } catch (error) {
        console.error('Error fetching products:', error);
        toast.error(t("toaster.error-occurred"));
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [currency, isUnlimited]);

  const currencySymbol = useMemo(() => getCurrencySymbol(currency), [currency]);

  return (
    <>
      <SignInPopup isOpen={isPopupOpen} setIsOpen={setIsPopupOpen} />
      <section id="pricing-section" className="relative py-12 sm:py-16 lg:py-20">
        <div className="container px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 space-y-12 mt-12">
          {/* Header */}
          <div className="text-center space-y-4 relative z-10">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="flex items-center justify-center gap-2 mb-4"
            >
              <Badge 
                variant="secondary" 
                className="px-4 py-1 bg-[#2D6250]/10 text-[#2D6250] border-0"
              >
                <Sparkles className="w-4 h-4 mr-1" />
                {t("product.pricing")}
              </Badge>
            </motion.div>
            
            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.1 }}
              className="text-3xl font-bold sm:text-4xl xl:text-5xl bg-clip-text text-transparent bg-gradient-to-r from-[#2D6250] via-[#2D6250]/80 to-[#2D6250]"
            >
              {t("product.choose-your-plan")}
            </motion.h2>
            
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="text-lg text-muted-foreground max-w-2xl mx-auto"
            >
              {t("product.choose-your-plan-description")}
            </motion.p>
          </div>

          {/* Price Cards */}
          <div className="grid grid-cols-1 gap-8 lg:grid-cols-3 lg:gap-6">
            {!isLoading ? (
              products.length > 0 ? (
                products.map((product, index) => {
                  const price = product.prices[0];
                  const currencyData = price?.currency_options?.[currency.toLowerCase()];
                  const amount = currencyData?.unit_amount ?? 0;
                  
                  return (
                    <motion.div
                      key={product.id}
                      initial="hidden"
                      animate="show"
                      variants={FADE_UP_ANIMATION_VARIANTS}
                      custom={index}
                      className={cn(
                        "relative",
                        (product.name.includes("Lifetime") || product.name.includes("Yearly")) &&
                          "lg:col-span-1"
                      )}
                    >
                      <Card className={cn(
                        "relative overflow-hidden",
                        "border-[#2D6250]/20 dark:border-[#2D6250]/10",
                        "transition-all duration-300",
                        "hover:border-[#2D6250]/40 hover:shadow-lg"
                      )}>
                        {/* Special Offer Badge */}
                        {product.originalAmount && (
                          <div className="py-4 left-0 right-0 flex justify-center">
                            <Badge 
                              variant="destructive" 
                              className="bg-rose-700 text-white cursor-default px-4 py-1 rounded-full shadow-lg animate-pulse"
                            >
                              <Sparkles className="w-4 h-4 mr-1" />
                              {t("campaign.discount")}
                            </Badge>
                          </div>
                        )}

                        {/* Card Header Icon */}
                        <div className="absolute top-4 right-4">
                          {product.name.includes("Daily") ? (
                            <Clock className="w-6 h-6 text-[#2D6250]" />
                          ) : product.name.includes("Monthly") ? (
                            <Zap className="w-6 h-6 text-[#2D6250]" />
                          ) : (
                            <Infinity className="w-6 h-6 text-[#2D6250]" />
                          )}
                        </div>

                        <div className="p-6 space-y-6">
                          {/* Header */}
                          <div className="space-y-2">
                            <h3 className="text-xl font-bold">
                              {t(`product.${product.id}.title`)}
                            </h3>
                            <p className="text-sm text-muted-foreground">
                              {t(`product.${product.id}.description`)}
                            </p>
                          </div>

                          {/* Price */}
                          <div className="text-center pt-4">
                            <div className="flex flex-col items-center gap-1">
                              {product.originalAmount && (
                                <div className="flex items-center opacity-75 line-through">
                                  <span className="text-sm font-medium mr-1 -mt-2">{currencySymbol}</span>
                                  <span className="text-xl font-bold">
                                    {formatAmountForDisplay(product.originalAmount / 100, currency, false)}
                                  </span>
                                </div>
                              )}
                              <div className="flex items-baseline">
                                <span className="text-lg font-medium mr-1 -mt-2">{currencySymbol}</span>
                                <span className="text-4xl font-bold">
                                  {formatAmountForDisplay(amount / 100, currency, false)}
                                </span>
                              </div>
                            </div>
                            <p className="text-sm text-muted-foreground mt-1">
                              {price?.type === "recurring"
                                ? t("product.subscription")
                                : t("product.one-time")}
                            </p>
                            {product.originalAmount && (
                              <p className="text-sm text-rose-800 font-medium mt-2 cursor-default">
                                {t("campaign.offer-ends")} {campaignEndDate}
                              </p>
                            )}
                            {product.originalAmount && price?.type === "recurring" && (
                              <div className="mt-2 flex items-center justify-center gap-1 text-rose-800 opacity-50 cursor-default">
                                <Info className="w-3 h-3" />
                                <p className="text-[10px] font-medium cursor-default">
                                  {t("campaign.pricing-notice")}
                                </p>
                              </div>
                            )}
                          </div>

                          {/* Features */}
                          <div className="space-y-3 pt-4">
                            {[
                              t(`product.${product.id}.description`),
                              t("product.ads-free"),
                              t("product.use-gpt4")
                            ].map((feature, idx) => (
                              <div key={idx} className="flex items-center gap-2">
                                <div className="rounded-full p-1 bg-[#2D6250]/10">
                                  <Check className="w-3 h-3 text-[#2D6250]" />
                                </div>
                                <span className="text-sm text-muted-foreground">
                                  {feature}
                                </span>
                              </div>
                            ))}
                          </div>

                          {/* Button */}
                          <Button
                            className={cn(
                              "w-full mt-6",
                              "bg-[#2D6250] hover:bg-[#2D6250]/90",
                              "text-white font-semibold"
                            )}
                            onClick={() => generateFormula(price.id, price.type)}
                          >
                            {t("product.buy-now")}
                          </Button>
                        </div>
                      </Card>
                    </motion.div>
                  );
                })
              ) : (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="col-span-3 text-center"
                >
                  <p className="text-muted-foreground">
                    {t("product.no-plans-available")}
                  </p>
                </motion.div>
              )
            ) : (
              // Loading skeletons
              Array.from({ length: 3 }).map((_, index) => (
                <motion.div
                  key={`skeleton-${index}`}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3, delay: index * 0.1 }}
                >
                  <Card className="p-6 space-y-6">
                    <div className="space-y-2">
                      <Skeleton className="h-6 w-3/4" />
                      <Skeleton className="h-4 w-1/2" />
                    </div>
                    <Skeleton className="h-12 w-24 mx-auto" />
                    <div className="space-y-2">
                      <Skeleton className="h-4 w-full" />
                      <Skeleton className="h-4 w-5/6" />
                      <Skeleton className="h-4 w-4/6" />
                    </div>
                    <Skeleton className="h-10 w-full" />
                  </Card>
                </motion.div>
              ))
            )}
          </div>

          {/* Footer */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className="text-center space-y-4"
          >
            <p className="text-sm text-muted-foreground">
              {t("product.30-days-refund")}
            </p>
            <div className="flex items-center justify-center gap-2 text-[#2D6250]">
              <Check className="w-4 h-4" />
              <span className="text-sm font-medium">
                {t("product.secure-payment")}
              </span>
            </div>
          </motion.div>
        </div>

        {/* Background Effects */}
        <div className="absolute inset-0 pointer-events-none">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-[#2D6250]/5 via-transparent to-transparent opacity-50" />
          <div className="absolute top-1/4 -left-1/4 w-1/2 h-1/2 bg-[#2D6250]/10 rounded-full blur-3xl" />
          <div className="absolute bottom-1/4 -right-1/4 w-1/2 h-1/2 bg-[#2D6250]/10 rounded-full blur-3xl" />
        </div>
      </section>
    </>
  );
}
