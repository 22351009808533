import { Analytics } from "@vercel/analytics/react";
import type { AppProps } from "next/app";
import "../styles/globals.css";
import { app } from "../initFirebase";
import i18next, { t } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { useEffect, useState } from "react";
import Header from "../components/Core/Header";
import Footer from "../components/Core/Footer";
import Head from "next/head";
import { Client } from "appwrite";
import { ThemeProvider } from "next-themes";
import { Sparkles } from "lucide-react";
import { useRouter } from "next/router";

app;
function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  i18next.use(LanguageDetector).init({
    fallbackLng: "en",
    resources: {
      de: {
        translation: require("../public/locales/de/translation.json"),
      },
      en: {
        translation: require("../public/locales/en/translation.json"),
      },
      es: {
        translation: require("../public/locales/es/translation.json"),
      },
      fr: {
        translation: require("../public/locales/fr/translation.json"),
      },
      it: {
        translation: require("../public/locales/it/translation.json"),
      },
      pt: {
        translation: require("../public/locales/pt/translation.json"),
      },
      tr: {
        translation: require("../public/locales/tr/translation.json"),
      },
    },
  });

  const [initialRenderComplete, setInitialRenderComplete] =
    useState<boolean>(false);

  useEffect(() => {
    setInitialRenderComplete(true);
  }, []);

  const handleCampaignClick = () => {
    // If already on pricing page, scroll to pricing section
    if (router.pathname === '/pricing') {
      const pricingSection = document.getElementById('pricing-section');
      if (pricingSection) {
        pricingSection.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      // If not on pricing page, navigate to it
      router.push('/pricing#pricing-section');
    }
  };

  if (!initialRenderComplete) return <></>;

  const client = new Client();

  // Campaign Banner
  const campaignBanner = (
    <button
      onClick={handleCampaignClick}
      className="w-full bg-rose-700 hover:bg-rose-800 text-white py-3 px-4 text-center relative z-50 transition-colors cursor-pointer"
    >
        {/* Campaign Banner - Only show if user is not on unlimited plan */}
            <div className="flex flex-col items-center gap-1">
              <div className="flex items-center justify-center gap-2">
                <Sparkles className="w-5 h-5" />
                <p className="font-medium">
                  {t("campaign.title")} {t("campaign.ends")}
                </p>
                <Sparkles className="w-5 h-5" />
              </div>
              <p className="text-xs opacity-80">
                {t("campaign.disclaimer")}
              </p>
            </div>
    </button>
  );

  client
    .setEndpoint("https://pitm.deploy.emreisik.dev/v1")
    .setProject("66af5d960035099a03ef");

  return (
    <ThemeProvider
      attribute="class"
      defaultTheme="light"
      disableTransitionOnChange
    >
      <Head>
        <title>
          Professionalize It To Me - GPT4o & Claude & AI Professional Message Generator
        </title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        />
      </Head>
      {campaignBanner}
      <Header />
      <Component {...pageProps} />
      {process.env.NODE_ENV === 'production' && <Analytics />}
      <Footer />
    </ThemeProvider>
  );
}

export default MyApp;
