import { Marked } from "@ts-stack/markdown";
import { motion, AnimatePresence } from "framer-motion";
import { t } from "i18next";
import toast from "react-hot-toast";
import { FADE_DOWN_ANIMATION_VARIANTS } from "../../enums/FramerEnums";
import FormArea from "../Hero/FormArea";
import MessageCounter from "./MessageCounter";
import RateIt from "../Core/RateIt";
import ResizablePanel from "./ResizablePanel";
import { ProFeatureProvider } from "./ProFeatureContext";
import { useState, useRef, useEffect } from "react";
import { useAuth } from "../../hooks/useAuth";
import { useDailyLimit } from "../../hooks/useDailyLimit";
import { useDailyLimitCheck } from "../../hooks/useDailyLimitCheck";
import useEmailGeneration from "../../hooks/useEmailGeneration";
import { useIp } from "../../hooks/useIp";
import useMixpanel from "../../hooks/useMixPanel";
import { auth } from "../../utils/Authentication";
import { isMessageContainsRestrictedWords, sendData } from "../../utils/main-functionality";
import { VibeType } from "../Dropdowns/DropDown";
import { Language } from "../Dropdowns/LanguageDropDown";
import { Length } from "../Dropdowns/LengthDropDown";
import { MessageType } from "../Dropdowns/MessageTypeDropDown";
import { Card } from "../ui/card";
import { Button } from "../ui/button";
import { Copy, Sparkles } from "lucide-react";
import getRemainingTime from "../../utils/getRemainingTime";

interface MessageGeneratorContentProps {
  onExampleSelect?: (content: string) => void;
}

const MessageGeneratorContent = ({ onExampleSelect }: MessageGeneratorContentProps) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [vibe, setVibe] = useState<VibeType>("professional");
  const [language, setLanguage] = useState<Language>("english");
  const [messageType, setMessageType] = useState<MessageType>("e-mail");
  const [length, setLength] = useState<Length>("short");
  const ipAddress = useIp();
  const messageInputRef = useRef<HTMLTextAreaElement>(null);
  const messageRef = useRef<null | HTMLDivElement>(null);
  const [isSelected, setIsSelected] = useState(false);
  const [useEmojis, setUseEmojis] = useState(false);
  const [useGPT4, setUseGPT4] = useState(false);
  const { limit, isLimitLoaded, fetchDailyLimit } = useDailyLimit();
  const { userLoading, unlimitedUser, setUnlimitedUser } = useAuth();
  const { checkDailyLimit } = useDailyLimitCheck(ipAddress?.toString() || "", unlimitedUser);
  const { track } = useMixpanel(process.env.NEXT_PUBLIC_MIXPANEL_KEY ?? "");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { hours, minutes } = getRemainingTime();
  const [isV2Enabled, setIsV2Enabled] = useState(false);
  const { createEmail, generatedEmail, setGeneratedEmail, isDone } = useEmailGeneration({
    emailInput: messageInputRef.current?.value || "",
    vibe,
    messageType,
    language,
    length,
    useEmojis,
    useGPT4,
    user: auth.currentUser?.email ?? ipAddress ?? "Anonymous",
    isV2: isV2Enabled
  });

  const handleExampleSelect = (content: string) => {
    setMessage(content);
    if (messageInputRef.current) {
      messageInputRef.current.value = content;
    }
  };

  const scrollToMessages = () => {
    if (messageRef.current !== null) {
      messageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const earlyReturnConditions = async () => {
    if (!messageInputRef.current?.value) {
      return t("toaster.enter-a-prompt");
    }

    if (isMessageContainsRestrictedWords(messageInputRef.current?.value)) {
      return t("toaster.enter-a-prompt");
    }

    if (!auth.currentUser) {
      setUnlimitedUser(false);
    }

    if (await checkDailyLimit()) {
      return t("toaster.daily-limit-reached");
    }

    return null;
  };

  const generateMessage = async (e: React.MouseEvent<HTMLButtonElement>) => {
    setLoading(true);
    setIsSelected(false);
    setGeneratedEmail("");
    e.preventDefault();

    const earlyReturnMessage = await earlyReturnConditions();
    if (earlyReturnMessage) {
      toast.error(earlyReturnMessage);
      setLoading(false);
      return;
    }

    await createEmail().finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (isDone) {
      scrollToMessages();
      const eventData = {
        "Message Type": messageType,
        Language: language,
        Length: length,
        Vibe: vibe,
        "Use Emojis": useEmojis,
        User: auth.currentUser?.email ?? ipAddress ?? "Anonymous",
        "Generated Message": generatedEmail.replace("[DONE]", ""),
        Prompt: messageInputRef.current?.value,
      };

      track("Generated Message", eventData);
      sendData(
        message,
        generatedEmail.replace("[DONE]", ""),
        language,
        vibe,
        messageType,
        length,
        ipAddress,
        auth.currentUser ? auth.currentUser : undefined
      );
    }
  }, [isDone]);

  useEffect(() => {
    if (!unlimitedUser && useGPT4) {
      setUseGPT4(false);
      setIsPopupOpen(true);
    }
  }, [useGPT4]);

  return (
    <motion.div
      className="w-full max-w-4xl mx-auto"
      initial="hidden"
      animate="show"
      variants={FADE_DOWN_ANIMATION_VARIANTS}
    >
      <div className="space-y-8 relative" style={{ isolation: 'isolate' }}>
        <MessageCounter
          userLoading={userLoading}
          isLimitLoaded={isLimitLoaded}
          unlimitedUser={unlimitedUser}
          limit={limit}
          hours={hours}
          minutes={minutes}
        />

        <ProFeatureProvider>
          <FormArea
            emailInputRef={messageInputRef}
            setEmail={setMessage}
            messageType={messageType}
            setMessageType={setMessageType}
            vibe={vibe}
            setVibe={setVibe}
            language={language}
            setLanguage={setLanguage}
            length={length}
            setLength={setLength}
            setUseEmojis={setUseEmojis}
            useGPT4={useGPT4}
            setUseGPT4={setUseGPT4}
            loading={loading}
            generateEmail={generateMessage}
            unlimitedUser={unlimitedUser}
            isV2={isV2Enabled}
            onV2Change={setIsV2Enabled}
          />
        </ProFeatureProvider>

        <ResizablePanel messageType={messageType}>
          <AnimatePresence mode="wait">
            {generatedEmail && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="space-y-8 my-8"
              >
                <div className="text-center">
                  <h2
                    className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#2D6250] to-[#2D6250]/80"
                    ref={messageRef}
                  >
                    {String(t("try-now.here-it-is"))}
                  </h2>
                </div>

                <Card className="overflow-hidden border-[#2D6250]/20">
                  <div 
                    className="p-6 bg-white dark:bg-gray-900 hover:bg-gray-50 dark:hover:bg-gray-800 transition-colors cursor-copy relative group"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        String(generatedEmail).replace(/<br\s*[\/]?>/gi, "\n")
                      );
                      toast(t("toaster.copied-to-clipboard"), {
                        icon: "✂️",
                      });
                    }}
                  >
                    <div
                      className="prose dark:prose-invert max-w-none"
                      dangerouslySetInnerHTML={{
                        __html: Marked.parse(
                          String(generatedEmail).replace(/<br\s*[\/]?>/gi, "\n")
                        ),
                      }}
                    />
                    <Button
                      variant="ghost"
                      size="icon"
                      className="absolute top-4 right-4 opacity-0 group-hover:opacity-100 transition-opacity"
                    >
                      <Copy className="w-4 h-4" />
                    </Button>
                  </div>
                </Card>

                <div className="space-y-4">
                  <RateIt
                    user={ipAddress?.toString() ?? "unknown"}
                    message={generatedEmail}
                    isSelected={isSelected}
                    setSelected={setIsSelected}
                  />

                  <Button
                    onClick={generateMessage}
                    className="w-full bg-[#2D6250] hover:bg-[#2D6250]/90 text-white"
                    size="lg"
                  >
                    <Sparkles className="w-4 h-4 mr-2" />
                    {String(t("try-now.regenerate"))}
                  </Button>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </ResizablePanel>
      </div>
    </motion.div>
  );
};

const MessageGenerator = () => {
  return <MessageGeneratorContent />;
};

export default MessageGenerator;

