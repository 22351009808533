import { cn } from "../../lib/utils";

interface LoadingDotsProps {
  color?: string;
  style?: "small" | "medium" | "large";
}

const LoadingDots = ({ color = "currentColor", style = "medium" }: LoadingDotsProps) => {
  const sizes = {
    small: "w-1 h-1",
    medium: "w-2 h-2",
    large: "w-3 h-3",
  };

  const gaps = {
    small: "gap-1",
    medium: "gap-2",
    large: "gap-3",
  };

  return (
    <span className="inline-flex items-center ml-2">
      <span className={cn("flex items-center", gaps[style])}>
        {[...Array(3)].map((_, i) => (
          <span
            key={i}
            className={cn(
              sizes[style],
              "animate-loader rounded-full",
              "bg-current opacity-75"
            )}
            style={{
              animationDelay: `${i * 0.15}s`,
              backgroundColor: color,
            }}
          />
        ))}
      </span>
    </span>
  );
};

export default LoadingDots;
