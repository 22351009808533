import { useState } from "react";
import { Toaster } from "react-hot-toast";
import MainLeft from "./MainLeft";
import SegmentSelector from "./SegmentSelector";

export default function Hero() {
  const [activeSegment, setActiveSegment] = useState<number>(1);

  return (
    <div className="relative min-h-screen bg-gradient-to-b from-[#2D6250]/5 via-white to-[#2D6250]/5 dark:from-gray-950 dark:via-gray-900 dark:to-gray-950">
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{ duration: 2000 }}
      />
      
      {/* Background Effects */}
      <div className="absolute inset-0">
        {/* Dot Pattern */}
        <div 
          className="absolute inset-0 opacity-[0.03]"
          style={{
            backgroundImage: `radial-gradient(#2D6250 0.5px, transparent 0.5px)`,
            backgroundSize: '24px 24px'
          }}
        />

        {/* Gradient Overlays */}
        <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-[#2D6250]/10 via-transparent to-transparent" />
        <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_bottom,_var(--tw-gradient-stops))] from-[#2D6250]/5 via-transparent to-transparent" />
      </div>
      
      {/* Main Content */}
      <div className="relative">
        <div className="mx-auto max-w-[1920px] px-4 sm:px-6 lg:px-8">
          <div className="min-h-screen py-12 sm:py-20 lg:py-32">
            <div className="grid grid-cols-1 items-start gap-6 sm:gap-8 lg:grid-cols-2 lg:gap-12">
              <MainLeft activeSegment={activeSegment} />
              <SegmentSelector activeSegment={activeSegment} onSegmentChange={setActiveSegment} />
            </div>
          </div>
        </div>

        {/* Decorative Elements */}
        <div className="pointer-events-none absolute inset-0 overflow-hidden">
          {/* Gradient Orbs */}
          <div className="absolute -left-1/4 top-1/4 h-[500px] w-[500px] rounded-full bg-[#2D6250]/10 blur-3xl" />
          <div className="absolute -right-1/4 top-1/2 h-[500px] w-[500px] rounded-full bg-[#2D6250]/5 blur-3xl" />
          
          {/* Light Beams */}
          <div className="absolute left-1/4 top-0 h-[1000px] w-px bg-gradient-to-b from-transparent via-[#2D6250]/20 to-transparent rotate-[20deg]" />
          <div className="absolute right-1/4 top-0 h-[1000px] w-px bg-gradient-to-b from-transparent via-[#2D6250]/20 to-transparent -rotate-[20deg]" />

          {/* Shimmer Effect */}
          <div 
            className="absolute inset-0 bg-[length:200%_auto] animate-shimmer"
            style={{
              backgroundImage: `linear-gradient(90deg, 
                transparent 0%, 
                #2D6250 25%, 
                transparent 50%, 
                transparent 100%
              )`,
              opacity: 0.02
            }}
          />
        </div>
      </div>

      {/* Fixed Background Elements */}
      <div className="fixed inset-0 -z-50">
        {/* Radial Gradient */}
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-white via-white to-[#2D6250]/5 dark:from-gray-950 dark:via-gray-900 dark:to-[#2D6250]/10 opacity-90" />
        
        {/* Grid Pattern */}
        <div 
          className="absolute inset-0 opacity-[0.015]"
          style={{
            backgroundImage: `linear-gradient(#2D6250 1px, transparent 1px),
                            linear-gradient(to right, #2D6250 1px, transparent 1px)`,
            backgroundSize: '64px 64px'
          }}
        />
      </div>
    </div>
  );
}
