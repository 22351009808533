import { useState } from "react";
import { Star } from "lucide-react";
import { Button } from "../ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../ui/card";
import { Textarea } from "../ui/textarea";
import { cn } from "../../lib/utils";
import useLanguage from "../../hooks/useLanguage";
import toast from "react-hot-toast";

export interface RateItProps {
  user: string;
  message: string;
  isSelected: boolean;
  setSelected: (value: boolean) => void;
  title?: string;
  description?: string;
}

type Language = 'en' | 'es' | 'de' | 'fr' | 'it' | 'pt' | 'tr';

type Translations = {
  [key in Language]: {
    title: string;
    description: string;
    placeholder: string;
    submit: string;
  };
};

const translations: Translations = {
  en: {
    title: "Rate your experience",
    description: "Help us improve by sharing your feedback",
    placeholder: "Tell us what you think (optional)",
    submit: "Submit Feedback"
  },
  es: {
    title: "Califica tu experiencia",
    description: "Ayúdanos a mejorar compartiendo tus comentarios",
    placeholder: "Dinos qué piensas (opcional)",
    submit: "Enviar Comentarios"
  },
  de: {
    title: "Bewerten Sie Ihre Erfahrung",
    description: "Helfen Sie uns, uns zu verbessern, indem Sie Ihr Feedback teilen",
    placeholder: "Teilen Sie uns Ihre Meinung mit (optional)",
    submit: "Feedback absenden"
  },
  fr: {
    title: "Évaluez votre expérience",
    description: "Aidez-nous à nous améliorer en partageant vos commentaires",
    placeholder: "Dites-nous ce que vous pensez (facultatif)",
    submit: "Soumettre des commentaires"
  },
  it: {
    title: "Valuta la tua esperienza",
    description: "Aiutaci a migliorare condividendo il tuo feedback",
    placeholder: "Dicci cosa ne pensi (opzionale)",
    submit: "Invia feedback"
  },
  pt: {
    title: "Avalie sua experiência",
    description: "Ajude-nos a melhorar compartilhando seu feedback",
    placeholder: "Diga-nos o que você pensa (opcional)",
    submit: "Enviar feedback"
  },
  tr: {
    title: "Deneyiminizi değerlendirin",
    description: "Geri bildiriminizi paylaşarak bize yardımcı olun",
    placeholder: "Bize ne düşündüğünüzü söyleyin (isteğe bağlı)",
    submit: "Geri bildirim gönder"
  }
};

export default function RateIt({ 
  user,
  message,
  isSelected,
  setSelected,
  title,
  description
}: RateItProps) {
  const { language } = useLanguage();
  const [rating, setRating] = useState<number>(0);
  const [hoveredRating, setHoveredRating] = useState<number>(0);
  const [feedback, setFeedback] = useState<string>("");

  const currentTranslations = translations[language.split('-')[0] as keyof Translations] || translations.en;

  const handleSubmit = async () => {
    if (rating > 0) {
      setSelected(true);
      await fetch('/api/feedback', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            rate: rating,
            user,
            message,
            metadata: JSON.stringify({ feedback, language, userAgent: navigator.userAgent }),
        }),
    });

      setRating(0);
      setFeedback("");
      toast.success("Feedback submitted successfully");
    }
  };

  return (
    <Card className="w-full max-w-md mx-auto">
      <CardHeader>
        <CardTitle>{title || currentTranslations.title}</CardTitle>
        <CardDescription>{description || currentTranslations.description}</CardDescription>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="flex justify-center space-x-2">
          {[1, 2, 3, 4, 5].map((value) => (
            <Button
              key={value}
              variant="ghost"
              size="icon"
              className={cn(
                "hover:bg-transparent p-0",
                (hoveredRating >= value || (!hoveredRating && rating >= value)) &&
                  "text-yellow-400"
              )}
              onClick={() => setRating(value)}
              onMouseEnter={() => setHoveredRating(value)}
              onMouseLeave={() => setHoveredRating(0)}
            >
              <Star
                className={cn(
                  "h-6 w-6 transition-transform",
                  (hoveredRating >= value || (!hoveredRating && rating >= value)) &&
                    "fill-current scale-110",
                  hoveredRating >= value && "text-yellow-400"
                )}
              />
              <span className="sr-only">Rate {value} stars</span>
            </Button>
          ))}
        </div>
        <Textarea
          placeholder={currentTranslations.placeholder}
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          className="min-h-[100px] resize-none"
        />
        <Button
          onClick={handleSubmit}
          disabled={rating === 0 || isSelected}
          className="w-full bg-[#2D6250] hover:bg-[#2D6250]/90 text-white"
        >
          {currentTranslations.submit}
        </Button>
      </CardContent>
    </Card>
  );
}