import { Fragment, useState, useRef } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { Check, ChevronsUpDown } from "lucide-react";
import { t } from "i18next";
import { PortalContainer } from "../ui/portal-container";

export const vibes = [
  "candid",
  "professional",
  "humorous",
  "empathetic",
  "persuasive",
  "assertive",
  "inspirational",
  "instructional",
  "collaborative",
  "diplomatic",
] as const;

export type VibeType = (typeof vibes)[number];

interface DropDownProps {
  vibe: VibeType;
  setVibe: (vibe: VibeType) => void;
}

export default function DropDown({ vibe, setVibe }: DropDownProps) {
  const [hoveredVibe, setHoveredVibe] = useState<VibeType | null>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative w-full cursor-pointer">
      <Listbox value={vibe} onChange={setVibe} as="div">
        <Listbox.Button 
          ref={buttonRef}
          onClick={() => setIsOpen(!isOpen)}
          className="relative w-full cursor-default rounded-lg bg-white dark:bg-slate-600 py-2 pl-3 pr-10 text-left border border-gray-300 dark:border-gray-500 focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
        >
          <span className="block truncate dark:text-slate-300">
            {t(`dropdown.${vibe}`)}
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ChevronsUpDown
              className="h-4 w-4 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>

        {isOpen && (
          <PortalContainer>
            <Transition
              show={isOpen}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options 
                static
                className="fixed mt-2 max-h-[60vh] overflow-auto rounded-md bg-white dark:bg-slate-600 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm md:text-base"
                style={{
                  width: buttonRef.current?.offsetWidth,
                  top: buttonRef.current?.getBoundingClientRect().bottom ?? 0,
                  left: buttonRef.current?.getBoundingClientRect().left ?? 0,
                }}
              >
                {vibes.map((vibeItem) => (
                  <Listbox.Option
                    key={vibeItem}
                    value={vibeItem}
                    onClick={() => setIsOpen(false)}
                    onMouseEnter={() => setHoveredVibe(vibeItem)}
                    onMouseLeave={() => setHoveredVibe(null)}
                    className={({ active }) =>
                      `relative cursor-pointer select-none py-3 md:py-2.5 pl-10 pr-4 ${
                        active
                          ? "bg-amber-100 dark:bg-slate-500 text-amber-900 dark:text-slate-300"
                          : "text-gray-900 dark:text-slate-300"
                      }`
                    }
                  >
                    {({ selected, active }) => (
                      <div className="relative">
                        <span
                          className={`block ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {t(`dropdown.${vibeItem}`)}
                        </span>
                        {selected && (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600" style={{ transform: 'translateX(-40px)' }}>
                            <Check className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                        <span className="block text-xs md:text-sm text-gray-500 dark:text-slate-400 mt-1 line-clamp-2">
                          {t(`dropdown.${vibeItem}-description`)}
                        </span>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </PortalContainer>
        )}
      </Listbox>
    </div>
  );
}
