import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import toast, { Toaster } from "react-hot-toast";
import { t } from "i18next";
import { useS3Upload } from "../../hooks/useS3Upload";
import { publicIpv4 } from "public-ip";
import { auth } from "../../utils/Authentication";
import { FADE_DOWN_ANIMATION_VARIANTS } from "../../enums/FramerEnums";
import { Marked } from "@ts-stack/markdown";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import { Button } from "../ui/button";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { Copy, FileText, Upload } from "lucide-react";
import LoadingDots from "../Core/LoadingDots";
import ResizablePanel from "../MessageGenerator/ResizablePanel";
import LanguageDropDown, { Language } from "../Dropdowns/LanguageDropDown";
import Dropzone, { DropzoneState } from "react-dropzone";

export default function CoverLetter() {
  const [pdfText, setPdfText] = useState<string>("");
  const [coverLetter, setCoverLetter] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [language, setLanguage] = useState<Language>("english");
  const [positionTitle, setPositionTitle] = useState<string>("");
  const [isUnlimited, setUnlimitedUser] = useState(false);
  const [ipAddress, setIpAddress] = useState<string>("0.0.0.0");
  const { isUploadLoading, s3Url, uploadError, uploadToS3 } = useS3Upload();
  const [dropText, setDropText] = useState(String(t("cover-letter-generator.drop-text")));
  const [file, setFile] = useState<File | null>(null);
  const [dropRejected, setDropRejected] = useState<boolean>(false);
  const [dropAccepted, setDropAccepted] = useState<boolean>(false);

  useEffect(() => {
    // Check for example data in localStorage
    const exampleData = localStorage.getItem("example-usage");
    if (exampleData) {
      const data = JSON.parse(exampleData);
      if (data.positionTitle) {
        setPositionTitle(data.positionTitle);
      }
      // Clear the example data after using it
      localStorage.removeItem("example-usage");
    }
  }, []);

  const handleDrop = async (acceptedFiles: File[]) => {
    setFile(acceptedFiles[0]);
  };

  const handleReject = () => {
    setDropRejected(true);
    setDropText(String(t("cover-letter-generator.drop-text")));
  };

  useEffect(() => {
    if (file) {
      if (file?.type !== "application/pdf") {
        handleReject();
        setFile(null);
      } else {
        uploadFile();
        setDropAccepted(true);
        setDropText(String(t("cover-letter-generator.upload")));
      }
    }
  }, [file]);

  const uploadFile = async () => {
    if (!file) return;
    await uploadToS3(file, "cover-letter");
  };

  const generateCoverLetter = async (s3Url: string) => {
    try {
      const response = await fetch("/api/cover-letter", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          pdfUrl: s3Url,
          language,
          positionTitle,
          user: auth.currentUser,
          ipAddress,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to generate cover letter");
      }

      const data = response.body;
      if (!data) return;

      const reader = data.getReader();
      const decoder = new TextDecoder();
      let done = false;

      while (!done) {
        const { value, done: doneReading } = await reader.read();
        done = doneReading;
        const chunkValue = decoder.decode(value);
        setCoverLetter((prev) => prev + chunkValue);
      }
    } catch (error) {
      toast.error(String(t("toaster.error-occurred")));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const init = async () => {
      const ip = await publicIpv4();
      setIpAddress(ip);
      
      if (auth.currentUser?.email) {
        const response = await fetch("/api/user/type", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ user: auth.currentUser }),
        });
        const { isUnlimitedUser } = await response.json();
        setUnlimitedUser(isUnlimitedUser);
      }
    };

    init();
  }, []);

  useEffect(() => {
    if (s3Url) {
      generateCoverLetter(s3Url);
    }
  }, [s3Url]);

  const renderDropzone = (state: DropzoneState) => {
    const { getRootProps, getInputProps, isDragActive } = state;
    return (
      <div
        {...getRootProps()}
        className={`
          cursor-pointer border-2 border-dashed rounded-lg p-6
          hover:border-[#2D6250]/30 hover:bg-[#2D6250]/5
          transition-colors duration-200
          flex flex-col items-center justify-center gap-4
          ${dropAccepted ? "border-green-500/50" : "border-[#2D6250]/20"}
          ${isDragActive ? "border-[#2D6250]/50 bg-[#2D6250]/10" : ""}
        `}
      >
        <input {...getInputProps()} />
        <Upload className={`w-8 h-8 ${dropAccepted ? "text-green-500" : "text-[#2D6250]"}`} />
        <p className="text-center text-sm text-muted-foreground">
          {isDragActive 
            ? String(t("cover-letter-generator.drop-your-cv-here"))
            : dropText
          }
        </p>
      </div>
    );
  };

  return (
    <motion.div
      animate={{
        marginTop: ["-200px", "0px"],
        opacity: [0, 1],
        xHeight: [0, "auto"],
      }}
      viewport={{ once: true }}
      className="xl:col-span-1"
      variants={FADE_DOWN_ANIMATION_VARIANTS}
    >
      <div className="container mx-auto px-4 py-8">
        <Card className="border-[#2D6250]/20">
          <CardHeader>
            <CardTitle className="flex items-center gap-2 text-[#2D6250]">
              <FileText className="w-5 h-5" />
              {t("cover-letter-generator.title")}
            </CardTitle>
          </CardHeader>
          <CardContent className="space-y-6">
            {!isUploadLoading && !coverLetter ? (
              <>
                <div className="space-y-4">
                  <div>
                    <Label>{t("cover-letter-generator.first-step")}</Label>
                    <LanguageDropDown
                      language={language}
                      setLanguage={setLanguage}
                    />
                  </div>

                  <div>
                    <Label>
                      {t("cover-letter-generator.second-step")}{" "}
                      <span className="text-muted-foreground">
                        {t("cover-letter-generator.second-step-description")}
                      </span>
                    </Label>
                    <Input
                      type="text"
                      placeholder="Position title"
                      value={positionTitle}
                      onChange={(e) => setPositionTitle(e.target.value)}
                      className="border-[#2D6250]/20 focus:border-[#2D6250]/30 focus:ring-[#2D6250]/20"
                    />
                  </div>

                  <div>
                    <Label>{t("cover-letter-generator.third-step")}</Label>
                    {dropRejected && (
                      <p className="text-red-500 text-sm mb-2">
                        {t("cover-letter-generator.unsupported-file-type")}
                      </p>
                    )}
                    <Dropzone
                      onDrop={handleDrop}
                      accept={{
                        "application/pdf": [".pdf"],
                      }}
                      onDropRejected={() => handleReject()}
                    >
                      {renderDropzone}
                    </Dropzone>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="text-center space-y-2">
                  <h2 className="text-2xl font-bold text-[#2D6250]">
                    {t("cover-letter-generator.your-cover-letter")}
                  </h2>
                  <p className="text-muted-foreground">
                    {t("cover-letter-generator.generated-cover-letter-description")}
                  </p>
                  <p className="text-sm text-muted-foreground">
                    {t("cover-letter-generator.generated-cover-letter-description-2")}
                  </p>
                </div>
              </>
            )}

            {(loading || isUploadLoading) && (
              <Button disabled className="w-full">
                <LoadingDots color="white" style="large" />
              </Button>
            )}

            <ResizablePanel>
              <AnimatePresence mode="wait">
                <motion.div className="space-y-4">
                  {coverLetter && (
                    <Card 
                      className="relative border-[#2D6250]/20 hover:bg-[#2D6250]/5 transition-colors cursor-copy group"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          String(coverLetter).replace(/<br\s*[\/]?>/gi, "\n")
                        );
                        toast(t("toaster.copied-to-clipboard"), {
                          icon: "✂️",
                        });
                      }}
                    >
                      <CardContent className="pt-6">
                        <div
                          className="prose dark:prose-invert max-w-none"
                          dangerouslySetInnerHTML={{
                            __html: Marked.parse(
                              String(coverLetter).replace(/<br\s*[\/]?>/gi, "\n")
                            ),
                          }}
                        />
                        <Button
                          variant="ghost"
                          size="icon"
                          className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity"
                        >
                          <Copy className="w-4 h-4" />
                        </Button>
                      </CardContent>
                    </Card>
                  )}
                </motion.div>
              </AnimatePresence>
            </ResizablePanel>
          </CardContent>
        </Card>
        <Toaster />
      </div>
    </motion.div>
  );
}
