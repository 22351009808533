import mixpanel from 'mixpanel-browser';

/**
 * Configuration options for Mixpanel initialization
 */
interface MixpanelConfig {
  debug?: boolean;
  ignore_dnt?: boolean;
}

/**
 * Generic type for event data
 */
type EventData = Record<string, any>;

/**
 * Initializes Mixpanel with the provided API key
 * @param key - Mixpanel API key
 * @param config - Optional configuration options
 * @throws Error if key is not provided
 */
export const initMixpanel = (key: string, config: MixpanelConfig = {}): void => {
  if (!key) {
    throw new Error('Mixpanel API key is required');
  }

  try {
    mixpanel.init(key, {
      debug: config.debug ?? false,
      ignore_dnt: config.ignore_dnt ?? false
    });
  } catch (error) {
    console.error('Failed to initialize Mixpanel:', error);
    throw new Error('Mixpanel initialization failed');
  }
};

/**
 * Tracks an event in Mixpanel with the provided data
 * @param eventName - Name of the event to track
 * @param eventData - Data associated with the event
 * @returns void
 */
export const trackEvent = (eventName: string, eventData: EventData = {}): void => {
  if (!eventName) {
    console.warn('Event name is required for tracking');
    return;
  }

  try {
    mixpanel.track(eventName, {
      timestamp: new Date().toISOString(),
      ...eventData
    });
  } catch (error) {
    console.error(`Failed to track event "${eventName}":`, error);
  }
};

/**
 * Identifies a user in Mixpanel
 * @param userId - Unique identifier for the user
 * @param userProperties - Additional user properties
 */
export const identifyUser = (userId: string, userProperties: EventData = {}): void => {
  if (!userId) {
    console.warn('User ID is required for identification');
    return;
  }

  try {
    mixpanel.identify(userId);
    if (Object.keys(userProperties).length > 0) {
      mixpanel.people.set(userProperties);
    }
  } catch (error) {
    console.error('Failed to identify user:', error);
  }
};

/**
 * Resets the Mixpanel state for the current user
 */
export const resetUser = (): void => {
  try {
    mixpanel.reset();
  } catch (error) {
    console.error('Failed to reset Mixpanel user:', error);
  }
};