import { createContext, useContext, useState, ReactNode } from "react";
import { useRouter } from "next/router";

interface ProFeatureContextType {
  showUpgradeDialog: boolean;
  setShowUpgradeDialog: (show: boolean) => void;
  handleUpgrade: () => void;
  activeFeature: keyof typeof proFeatures | null;
  setActiveFeature: (feature: keyof typeof proFeatures | null) => void;
}

const proFeatures = {
  "rich-text": {
    title: "email-editor.pro-features.rich-text",
    description: "email-editor.pro-features.rich-text-description",
    icon: "✍️",
    features: [
      "Bold, italic, and list formatting",
      "Image and file attachments",
      "Link insertion",
      "Advanced text editing"
    ]
  },
  "attachments": {
    title: "email-editor.pro-features.attachments",
    description: "email-editor.pro-features.attachments-description",
    icon: "📎",
    features: [
      "File attachments up to 25MB",
      "Multiple file support",
      "Image preview",
      "Drag and drop support"
    ]
  },
  "cc-bcc": {
    title: "email-editor.pro-features.cc-bcc",
    description: "email-editor.pro-features.cc-bcc-description",
    icon: "📧",
    features: [
      "CC and BCC recipients",
      "Multiple recipient support",
      "Email address validation",
      "Contact suggestions"
    ]
  },
  "templates": {
    title: "email-editor.pro-features.templates",
    description: "email-editor.pro-features.templates-description",
    icon: "📝",
    features: [
      "Save email templates",
      "Custom variables",
      "Template categories",
      "Quick access favorites"
    ]
  },
  "gpt4": {
    title: "email-editor.pro-features.gpt4",
    description: "email-editor.pro-features.gpt4-description",
    icon: "🤖",
    features: [
      "Advanced AI model",
      "Better understanding",
      "More creative responses",
      "Complex task handling"
    ]
  },
  "richTextV2": {
    title: "email-editor.pro-features.rich-text-v2",
    description: "email-editor.pro-features.rich-text-v2-description",
    icon: "✨",
    features: [
      "Smart message layouts",
      "Context-aware editing",
      "Platform-specific formatting",
      "Enhanced composition experience"
    ]
  }
} as const;

export type ProFeature = keyof typeof proFeatures;
export { proFeatures };

const ProFeatureContext = createContext<ProFeatureContextType | undefined>(undefined);

interface ProFeatureProviderProps {
  children: ReactNode;
}

export function ProFeatureProvider({ children }: ProFeatureProviderProps) {
  const router = useRouter();
  const [showUpgradeDialog, setShowUpgradeDialog] = useState(false);
  const [activeFeature, setActiveFeature] = useState<ProFeature | null>(null);

  const handleUpgrade = () => {
    setShowUpgradeDialog(false);
    router.push("/pricing");
  };

  return (
    <ProFeatureContext.Provider
      value={{
        showUpgradeDialog,
        setShowUpgradeDialog,
        handleUpgrade,
        activeFeature,
        setActiveFeature,
      }}
    >
      {children}
    </ProFeatureContext.Provider>
  );
}

export function useProFeature() {
  const context = useContext(ProFeatureContext);
  if (context === undefined) {
    throw new Error("useProFeature must be used within a ProFeatureProvider");
  }
  return context;
}

export function useProFeatureUpgrade() {
  const { handleUpgrade } = useProFeature();
  return handleUpgrade;
}

export function useProFeatureDialog() {
  const { showUpgradeDialog, setShowUpgradeDialog, activeFeature, setActiveFeature } = useProFeature();
  
  const openDialog = (feature: ProFeature) => {
    setActiveFeature(feature);
    setShowUpgradeDialog(true);
  };

  const closeDialog = () => {
    setShowUpgradeDialog(false);
    setActiveFeature(null);
  };

  return {
    isOpen: showUpgradeDialog,
    activeFeature,
    openDialog,
    closeDialog,
  };
}

export function useProFeatureInfo(feature: ProFeature) {
  return proFeatures[feature];
}